import React from 'react';

export default function AddButton({ onClick }) {

  return (
    <div className="flex px-8 justify-center items-center py-3 ">
      <div className="h-px w-full bg-gray-200"></div>
      <div
        className="flex items-center justify-center cursor-pointer transform duration-500 hover:rotate-90 transition-all"
        onClick={onClick}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="#86704D"
          className="w-6 h-6 m-2 "
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M12 6v6m0 0v6m0-6h6m-6 0H6"
          ></path>
        </svg>
      </div>
      <div className="h-px w-full bg-gray-200"></div>
    </div>
  );

}
