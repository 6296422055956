import React from 'react';

export default function MacroAlimentsVisual({
  sumProteins,
  sumFats,
  sumCarbohydrates
}) {

  let macro = [
    {
      name: 'Proteine',
      weight: sumProteins
    },
    {
      name: 'Grassi',
      weight: sumFats
    },
    {
      name: 'Carboidrati',
      weight: sumCarbohydrates
    },
  ];

  return (
    <div className={`flex flex-row justify-center items-center space-x-4`}>
      {macro.map((m, i) => {
        return (
          <div key={i} className={`px-1 w-1/3 bg-white rounded-lg shadow-md`}>
            <div
              className='px-2 py-3 flex-col items-center justify-around rounded-2xl'>
              {m.icon}
              <div
                className='flex flex-col items-center justify-center'>
                <p
                  className='color-dark my-2 font-bold'>
                  {m.name}
                </p>
              </div>
              <div className={`flex flex-row w-full items-center justify-center px-1 `}>
                <p
                  className='color-light font-w-regular'>
                  {m.weight ? m.weight.toFixed(2) : 0}g
                </p>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
