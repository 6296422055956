import React from "react";
import uniq from 'lodash.uniq';
import InputField from "@components/fields/Input";
import Select from "@components/fields/Select";

import RemoveButton from '@components/RemoveButton';

export default function SupplementFields(props) {

  const { index, remove, supplementBrands, supplementOptions, setFieldValue, values } = props;

  return (
    <div className="relative p-4 my-6 bg-white rounded-lg">

      <RemoveButton onClick={remove} />

      <div className="flex space-x-8 pt-4">
        <div className="w-2/6 flex flex-col space-y-4">
          <Select
            name={`supplements.${index}.brand`}
            label="Brand"
            options={supplementBrands}
            onChange={(value) => {
              setFieldValue(`supplements.${index}.brand`, value.value);
              setFieldValue(`supplements.${index}.supplementId`, '');
            }}
          />
        </div>
        <div className="w-3/6 flex flex-col space-y-4">
          <Select
            name={`supplements.${index}.supplementId`}
            label="Nome"
            options={values && supplementOptions.filter((value) => value.brand === values.brand)}
            onChange={(value) => {
              setFieldValue(`supplements.${index}.supplementId`, value.value);
              setFieldValue(`supplements.${index}.unitMeasure`, value.amount);
            }}
          />
        </div>

        <div className="w-1/6">
          <InputField name={`supplements.${index}.amount`} label="Quantità" type="string" />
        </div>

      </div>
    </div>
  );
}
