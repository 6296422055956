import React from "react";
import InputField from "@components/fields/Input";
import Select from "@components/fields/Select";

export default function PlanSupplement({ index, remove, supplementOptions, supplementBrands, planMeals, setFieldValue, values }) {

  return (
    <div className="relative text-gray-900 text-sm font-medium pt-4">
      <div className={`flex flex-col pr-4`}>
        <div className="px-6 pt-2 whitespace-nowrap">
        <Select
            name={`planSupplements.${index}.mealName`}
            label="Nome Pasto"
            options={planMeals}
            onChange={(value) => {
              setFieldValue(`planSupplements.${index}.mealName`, value.value);
            }}
          />
        </div>
        <div className="px-6 pt-2 whitespace-nowrap">
        <Select
            name={`planSupplements.${index}.brand`}
            label="Brand"
            options={supplementBrands}
            onChange={(value) => {
              setFieldValue(`planSupplements.${index}.brand`, value.value);
              setFieldValue(`planSupplements.${index}.supplementId`, '');
            }}
          />
        </div>
        <div className="px-6 pt-2 whitespace-nowrap mt-2">
        <Select
            name={`planSupplements.${index}.supplementId`}
            label="Nome"
            options={values && supplementOptions?.filter((value) => value.brand === values.brand)}
            onChange={(value) => {
              setFieldValue(`planSupplements.${index}.supplementId`, value.value);
              setFieldValue(`planSupplements.${index}.unitMeasure`, value.amount);
            }}
          />
        </div>
        <div className="px-6 pt-2 whitespace-nowrap text-center mt-2">
          <div className="flex flex-col items-start">
            <p className="text-left">Quantità</p>
            <InputField name={`planSupplements.${index}.amount`} placeholder="gr/caps" type="text" />
          </div>
        </div>
      </div>
      <div className="absolute inset-y-1/2 right-0" onClick={() => remove()}>
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mt-4 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="#86704D">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
      </div>
    </div>
  );
}
