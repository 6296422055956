import React, { useState, useMemo, useRef } from 'react';

import Select from '@components/fields/Select';
import InputField from '@components/fields/Input';
import Textarea from '@components/fields/Textarea';
import Toggle from '@components/fields/Toggle';

import ExerciseStats from './ExerciseStats';
import RemoveButton from '@components/RemoveButton';

export default function Exercise({
  index,
  remove,
  moveWorkoutExercise,
  exerciseCategories, exerciseOptions,
  setFieldValue,
  values,
  workoutExerciseId,
  ...props
}) {

  const [showBox, setShowBox] = useState(false);

  const [showStats, setShowStats] = useState(false);

  return (
    <div className="relative rounded-lg bg-white shadow my-8">

      {showStats ? <ExerciseStats {...{ workoutExerciseId, setShowStats }} /> : null}

      <div className="flex absolute top-0 left-0 p-4">
        <div className="cursor-pointer mr-1" onClick={() => props.moveUp() }>&#x25B2;</div>
        <div className="cursor-pointer" onClick={() => props.moveDown()}>&#x25BC;</div>
      </div>

      <RemoveButton onClick={remove} />

      <div className="pt-12">
        <div className="grid grid-cols-2 justify-center">
          {/* Exercise - note */}
          <div className="flex flex-col justify-start px-4 pb-4">
            <Select
              name={`exercises.${index}.category`}
              label="Categoria"
              options={exerciseCategories}
              onChange={(value) => {
                setFieldValue(`exercises.${index}.category`, value.value);
                setFieldValue(`exercises.${index}.exerciseId`,'' );
              }}
            />
            <div className="mt-4">
              <Select
                name={`exercises.${index}.exerciseId`}
                label="Esercizio"
                options={exerciseOptions.filter((value) => value.category === values.category)}
                onChange={(value) => {
                  setFieldValue(`exercises.${index}.exerciseId`, value.value);
                }}
              />
            </div>
            <div
              className="mt-4 flex items-center space-x-2 cursor-pointer underline text-sm text-gray-400"
              onClick={() => setShowBox(true)}
            >
              {!showBox && (
                <>
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M8 4H6a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-2m-4-1v8m0 0l3-3m-3 3L9 8m-5 5h2.586a1 1 0 01.707.293l2.414 2.414a1 1 0 00.707.293h3.172a1 1 0 00.707-.293l2.414-2.414a1 1 0 01.707-.293H20"
                      />
                    </svg>
                  </span>
                  <span>Aggiungi nota</span>
                </>
              )}
            </div>
            {showBox && (
              <div className="mt-4 flex items-center">
                <Textarea name={`exercises.${index}.notes`} label="Note" />
              </div>
            )}
          </div>
          {/* Series - Recovery */}
          <div className="px-4 pb-4 flex flex-col">
            <div className="flex space-x-4 justify-start">
              <InputField
                name={`exercises.${index}.series`}
                label="Serie e ripetizioni"
                placeholder="Es. 2x10"
                type="text"
                disabled={values.haveProgression}
              />
              <InputField
                name={`exercises.${index}.recovery`}
                label="Recupero"
                type="text"
                disabled={values.haveProgression}
                hint="Sec"
              />
            </div>

            <div className="flex space-x-4 justify-start">
              <div className="w-1/2">
                <InputField
                  name={`exercises.${index}.weightAdvisor`}
                  label="Peso Indicato (Advisor)"
                  placeholder="Es. 10"
                  type="text"
                  hint="kg"
                />
              </div>
              <div className="w-1/2 pt-11">
                <Toggle name={`exercises.${index}.haveProgression`} label="Progressioni" />
              </div>
            </div>
            <p
              className="cursor-pointer underline text-sm text-p1-600"
              onClick={() => setShowStats(!showStats)}
            >
              {!showStats ? 'Mostra Statistiche' : null}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
