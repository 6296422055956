import { gql } from "@apollo/client";

import { EXERCISE_FIELDS, MEAL_FIELDS } from "./fragments";

export const UPDATE_WORKOUT = gql`
  ${EXERCISE_FIELDS}
  mutation UpdateWorkout($id: ID!, $input: WorkoutInput!) {
    updateWorkout(id: $id, input: $input) {
      id
      name
      category
      repetition
      exercises {
        ...exerciseFields
      }
    }
  }
`;

export const DELETE_WORKOUT = gql`
  mutation DeleteWorkout($id: ID!) {
    deleteWorkout(id: $id) {
      id
    }
  }
`;

export const UPDATE_WORKOUT_SHEET = gql`
  mutation UpdateWorkoutSheet($id: ID!, $input: WorkoutSheetInput!) {
    updateWorkoutSheet(id: $id, input: $input) {
      id
      name
      objective
      weeklyWorkout
      state
      workouts {
        id
        name
        category
        repetition
      }
      progressions {
        id
        series
        load
        recovery
      }
    }
  }
`;

export const WORKOUT_SHEET_STATE = gql`
  mutation WorkoutSheetState($id: ID!, $state: String!) {
    workoutSheetState(id: $id, state: $state) {
      id
      state
    }
  }
`;

export const CREATE_WORKOUT = gql`
  mutation CreateWorkout($id: ID!) {
    createWorkout(id: $id) {
      id
      name
      workouts {
        id
        name
        category
        repetition
      }
    }
  }
`;

export const UPDATE_NUTRITIONAL_PLAN = gql`
  mutation UpdateNutritionalPlan($id: ID!, $input: NutritionalPlanInput!) {
    updateNutritionalPlan(id: $id, input: $input) {
      id
      name
      objective
      category
      cyclization
      state
      notes {
        id
        title
        description
      }
      planSupplements {
        id
        nutritionalPlanId
        supplementId
        mealName
        brand
        amount
        unitMeasure
      }
      days {
        id
        name
        category
      }
    }
  }
`;

export const NUTRITIONAL_PLAN_STATE = gql`
  mutation NutritionalPlanState($id: ID!, $state: String!) {
    nutritionalPlanState(id: $id, state: $state) {
      id
      state
    }
  }
`;

export const CREATE_NUTRITIONAL_DAY = gql`
  mutation CreateNutritionalDay($id: ID!) {
    createNutritionalDay(id: $id) {
      id
      name
      days {
        id
        name
        category
      }
    }
  }
`;

export const UPDATE_NUTRITIONAL_DAY = gql`
  ${MEAL_FIELDS}
  mutation UpdateNutritionalDay($id: ID!, $input: NutritionalDayInput!) {
    updateNutritionalDay(id: $id, input: $input) {
      id
      name
      category
      notes
      carbohydrates
      proteins
      fats
      sodium
      water
      vegetables
      meals {
        ...mealFields
      }
      supplements {
        id
        nutritionalDayId
        nutritionalMealId
        supplementId
        brand
        amount
        unitMeasure
      }
    }
  }
`;

export const COPY_NUTRITIONAL_DAY = gql`
  mutation CopyNutritionalDay($id: ID!, $source: Int!) {
    copyNutritionalDay(id: $id, source: $source) {
      id
      name
    }
  }
`;

export const UPDATE_NUTRITIONAL_MEAL = gql`
  ${MEAL_FIELDS}
  mutation UpdateNutritionalMeal($id: ID!, $input: NutritionalMealInput!) {
    updateNutritionalMeal(id: $id, input: $input) {
      ...mealFields
      supplements {
        id
        nutritionalDayId
        nutritionalMealId
        supplementId
        brand
        amount
        unitMeasure
      }
    }
  }
`;

export const DELETE_NUTRITIONAL_DAY = gql`
  mutation DeleteNutritionalDay($id: ID!) {
    deleteNutritionalDay(id: $id) {
      id
    }
  }
`;
