import { Controller } from "stimulus"

import * as echarts from 'echarts/core';
import { LegendComponent } from 'echarts/components';
import { RadarChart } from 'echarts/charts';
import { SVGRenderer, CanvasRenderer  } from 'echarts/renderers';

export default class extends Controller {
  connect() {

    echarts.use([LegendComponent, RadarChart, SVGRenderer, CanvasRenderer]);

    // initialize the echarts instance
    let myChart = echarts.init(document.querySelector('#graph'), { renderer: 'svg' });
    let categories = JSON.parse(document.querySelector('#graph').getAttribute('data-categories'));
    let values = JSON.parse(document.querySelector('#graph').getAttribute('data-values'));

    // Draw the chart
    myChart.setOption({
      // legend: {
      //   data: ['Punteggio']
      // },
      radar: {
        shape: 'circle',
        indicator: categories
      },
      series: [
        {
          name: 'Punteggio',
          type: 'radar',
          color: '#0163ff',
          areaStyle: "rgba(2,99,255, 0.5)",
          data: values
        }
      ]
    });
  }
}



