import React from 'react';

export const CloseIcon = ({ onClick }) => {

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-5 w-5 cursor-pointer"
      fill="none"
      viewBox="0 0 24 24"
      stroke="#8990AD"
      onClick={onClick}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M6 18L18 6M6 6l12 12"
      />
    </svg>
  )

}


export const AddIcon = ({ onClick }) => {

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="#6c7381"
      className="w-5 h-5"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 6v6m0 0v6m0-6h6m-6 0H6"
      ></path>
    </svg>
  )

};

export const Remove = ({ }) => {

  return (
    <svg id="e-remove" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <rect id="Rectangle_43" data-name="Rectangle 43" width="16" height="16" fill="none"/>
      <g id="Group_94" data-name="Group 94" transform="translate(1 1)">
        <path id="Path_43" data-name="Path 43" d="M14.7,1.3a.967.967,0,0,0-1.4,0L8,6.6,2.7,1.3a.967.967,0,0,0-1.4,0,.967.967,0,0,0,0,1.4L6.6,8,1.3,13.3a.967.967,0,0,0,0,1.4A.908.908,0,0,0,2,15a.908.908,0,0,0,.7-.3L8,9.4l5.3,5.3a.967.967,0,0,0,1.4,0,.967.967,0,0,0,0-1.4L9.4,8l5.3-5.3A.967.967,0,0,0,14.7,1.3Z" transform="translate(-1 -1)" fill="#8990ad"/>
      </g>
    </svg>
  );

}
