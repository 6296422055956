import React from "react";
import apolloClient from './../apollo-client';
import { ApolloProvider } from '@apollo/client';

import NotesForm from "./NotesForm";

export default function NutritionalPlanNotes({ id }) {

  return (
    <ApolloProvider client={apolloClient}>
      <NotesForm nutritionalPlanId={id} />
    </ApolloProvider>
  );
}
