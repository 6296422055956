import React, { useMemo } from "react";
import uniq from 'lodash.uniq';
import apolloClient from './../apollo-client';
import { ApolloProvider } from '@apollo/client';
import PlanSupplementForm from "./PlanSupplementForm";

export default function NutritionalPlanSupplements({ id, supplementsList, planMeals}) {

  // console.log('supplementsList', supplementsList)

  const supplementBrands = useMemo(() =>
  uniq(supplementsList.map((w) => w.brand))
  .sort()
  .map((d) => ({ label: d, value: d }))
, [supplementsList]);

const supplementOptions = useMemo(() =>
  supplementsList.map((w) => ({
    label: w.label,
    value: w.id,
    amount: w.amount,
    brand: w.brand
  }))
  , [supplementsList]);


  return (
    <ApolloProvider client={apolloClient}>
      <PlanSupplementForm nutritionalPlanId={id} {...{supplementOptions, supplementBrands, planMeals}}/>
    </ApolloProvider>
  );
}
