import React, {useEffect, useState, useContext} from "react";
import LoadingContext from "../../LoadingContext";
import { Formik } from 'formik';
import Select from '@components/fields/Select';
import ClipLoader from "react-spinners/ClipLoader";

import { useMutation } from '@apollo/client';
import { COPY_NUTRITIONAL_DAY } from 'graphql/mutations';

export default function CopyFromDay({nutritionalDayId, days}) {
  const { setLoadingState } = useContext(LoadingContext);

  const daysOptions = days.filter(p => p.id != nutritionalDayId).map((p) => ({ label: p.name, value: p.id }));

  const [selectedDay, setSelectedDay] = useState(null);

  const [copyNutritionalDay, { data, loading, error }] = useMutation(COPY_NUTRITIONAL_DAY, {
    variables: {
      id: nutritionalDayId,
      source: selectedDay
    },
    onCompleted: () => {
      window.location.reload();
    }
  });
  
  useEffect(() => {
    if (selectedDay) {
      setLoadingState(loading);
      copyNutritionalDay();
    }
  }, [selectedDay]);

  return (
    <Formik
      enableReinitialize
      validateOnMount
    >
      {(props) => (
      <form onSubmit={props.handleSubmit} className="form">
        {error && <div className="alert alert-danger">{error.message}</div>}
        { loading ? (
          <>
            <ClipLoader />
            <div className="flex items-center space-x-8 py-4">
              <p>Questa operazione potrebbe richiedere un pò di tempo, prego attendere che l'operazione sia ultimata.</p>
              <p>La pagina si ricaricherà da sola una volta che l'operazione sarà completata.</p>
            </div>
          </>
        ) : (<div className="w-96 pt-4">
        <Select
          name={`nutritionalDayId`}
          label="Copia da"
          options={daysOptions}
          onChange={(value) => {
            setSelectedDay(value.value);
          }}
        />
        </div>) }
        
       
      </form>)}
    </Formik>
  );

}