import React, { useState } from "react";

import { ApolloProvider } from '@apollo/client';

import apolloClient from '../apollo-client';

import Form from './Form';

export default function ProgressionForm(props) {

  return (
    <ApolloProvider client={apolloClient}>
      <Form {...props} />
    </ApolloProvider>
  );

}
