import { Controller } from "stimulus"
import Inputmask from "inputmask";

export default class extends Controller {
  static targets = ["input"]

  initialize() {
    Inputmask({ regex: "[a-gA-G]{1,7}", jitMasking: true }).mask(this.inputTarget);
  }

}