import React from "react";
import { Switch } from "@headlessui/react";
import { useField } from "formik";
import cx from 'classnames';

export default function Toggle({ name, label }) {

  const [field, meta, { setValue, setTouched }] = useField(name);

  const enabled = field.value;

  return (
    <Switch.Group as="div" className="flex items-center">
      <Switch
        checked={enabled}
        onChange={() => setValue(!enabled)}
        className={cx("relative inline-flex flex-shrink-0 h-4 w-9 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white", {
          "bg-p1-600": enabled,
          "bg-gray-200": !enabled
          }
        )}
      >
        <span
          aria-hidden="true"
          className={cx("pointer-events-none inline-block h-3 w-3 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200", {
            "translate-x-5": enabled,
            "translate-x-0": !enabled
            }
          )}
        />
      </Switch>
      <Switch.Label as="span" className="ml-3 ">
        <span className="label-question-toggle text-sm">{label}</span>
      </Switch.Label>
    </Switch.Group>
  );
}