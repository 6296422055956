import React, { useState, useEffect, useCallback } from 'react';
import { useFormikContext } from 'formik';
import { debounce } from 'lodash';

const AutoSave = ({ debounceMs = 1000 }) => {

  const formik = useFormikContext();
  const [isSaved, setIsSaved] = useState(null);

  const debouncedSubmit = useCallback(
    debounce(() => {
      return formik.submitForm().then(() => setIsSaved(true));
    }, debounceMs),
    [formik.submitForm, debounceMs]
  );

  useEffect(() => debouncedSubmit, [debouncedSubmit, formik.values]);

  return (
    <p className="text-center text-sm">
      {!!formik.isSubmitting ? (
        <span className="text-white bg-p5-500 px-3 py-2 rounded-md shadow-md animate-pulse">
          Sto salvando...
        </span>
      ) : isSaved ? (
        <span className="text-white bg-green-500 px-3 py-2 rounded-md shadow-md">
          Modifiche salvate correttamente.
        </span>
      ) : (
        <span className="text-white bg-yellow-400 px-3 py-2 rounded-md shadow-md">
          In attesa di modifiche
        </span>
      )}
    </p>
  );
};

export default AutoSave;
