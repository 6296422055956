import React, {useMemo} from "react";
import uniq from 'lodash.uniq';
import { Formik, FieldArray } from 'formik';

import NutritionalFoodForm from './NutritionalFoodForm';
import SupplementFields from './../../shared/SupplementFields';
import ClipLoader from "react-spinners/ClipLoader";

import AutoSave from '@components/AutoSave';
import AddButton from './../../AddButton';

import { useQuery, useMutation } from '@apollo/client';

import { NUTRITIONAL_MEAL, MACRO_NUTRITIONAL_DAY } from 'graphql/queries';
import { UPDATE_NUTRITIONAL_MEAL } from 'graphql/mutations';

export default function NutritionalMealForm({ url, nutritionalDayId, mealId, foodCategories, foodOptions, supplementBrands, supplementOptions, ...props }) {

  const { loading, data, error } = useQuery(NUTRITIONAL_MEAL, { variables: { id: mealId } });

  const [updateNutritionalMeal] = useMutation(UPDATE_NUTRITIONAL_MEAL, {
    refetchQueries: [{ query: MACRO_NUTRITIONAL_DAY, variables: { id: nutritionalDayId } }]
  });

  if (loading) {
    return <ClipLoader />;
  }

  if (error) return `Error! ${error}`;

  const onSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    updateNutritionalMeal({
      variables: {
        id: mealId,
        input: {
          name: values.name,
          foodsAttributes: values.foods.map((food) => ({
            id: food.id,
            position: food.position,
            nutritionalMealId: food.nutritionalMealId,
            foodId: food.foodId,
            category: food.category || "",
            carbohydrates: parseFloat(food.carbohydrates) || 0,
            proteins: parseFloat(food.proteins) || 0,
            fats: parseFloat(food.fats) || 0,
            salt: parseFloat(food.salt) || 0,
            amount: food.amount || 0,
            unitMeasure: food.unitMeasure || "gr",
            spanAmount: food.spanAmount || 0,
            unitSpanMeasure: food.unitSpanMeasure || "",
            _destroy: food._destroy,
            childrenAttributes: food.childrenAttributes?.map((a) => ({
              id: a.id,
              foodId: a.foodId,
              category: a.category || "",
              carbohydrates: parseFloat(a.carbohydrates) || 0,
              proteins: parseFloat(a.proteins) || 0,
              fats: parseFloat(a.fats) || 0,
              salt: parseFloat(a.salt) || 0,
              amount: a.amount || 0,
              unitMeasure: a.unitMeasure || "",
              spanAmount: a.spanAmount || 0,
              unitSpanMeasure: a.unitSpanMeasure || "",
              _destroy: a._destroy,
            })),
          })),
          supplementsAttributes: values.supplements.map( (w) => ({
            id: w.id,
            brand: w.brand,
            supplementId: w.supplementId,
            nutritionalMealId: w.nutritionalMealId,
            nutritionalDayId: w.nutritionalDayId,
            amount: w.amount,
            unitMeasure: w.unitMeasure,
            _destroy: w._destroy,
          })) || []
        },
      },
      onCompleted: () => {
        setSubmitting(false);
      }
    });
  };

  const initialValues = {
    id: data.nutritionalMeal.id,
    name: data.nutritionalMeal.name,
    foods: data.nutritionalMeal.foods.map((w) => ({
      id: w.id,
      position: w.position,
      foodId: w.foodId,
      nutritionalMealId: w.nutritionalMealId,
      category: w.category || '',
      amount: w.amount || 0,
      unitMeasure: w.unitMeasure || "",
      spanAmount: w.spanAmount || 0,
      unitSpanMeasure: w.unitSpanMeasure || "",
      parent_id: w.parent_id || null,
      carbohydrates: w.carbohydrates || 0,
      proteins: w.proteins || 0,
      fats: w.fats || 0,
      _destroy: 0,
      childrenAttributes: w.childrenAttributes.map((a) => ({
        id: a.id,
        foodId: a.foodId,
        category: a.category || '',
        amount: a.amount || 0,
        unitMeasure: a.unitMeasure || "",
        spanAmount: a.spanAmount || 0,
        unitSpanMeasure: a.unitSpanMeasure || "",
        _destroy: 0,
      })),
    })),
    supplements: data.nutritionalMeal.supplements.map((e) => ({
      id: e.id,
      supplementId: e.supplementId,
      nutritionalMealId: e.nutritionalMealId,
      nutritionalDayId: e.nutritionalDayId,
      brand: e.brand || '',
      amount: e.amount || '0',
      unitMeasure: e.unitMeasure,
      _destroy: 0,
    })),
  }

  return (
    <Formik
      enableReinitialize
      validateOnMount
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {(props) => (
        <form onSubmit={props.handleSubmit} className="form">

          <div className="mt-4 flex justify-end">

            {props.dirty && (
              <span className="text-white bg-yellow-400 px-3 py-2 rounded-md shadow-md mr-4">
                Modifiche non salvate!
              </span>
            )}

            <div className="btn btn-dark" onClick={() => { !props.isSubmitting && props.handleSubmit() } }>{props.isSubmitting ? 'Salvataggio...' : 'Salva'}</div>

          </div>

          <h1 className="font-bold text-xl pb-2">Menu</h1>

          <FieldArray name="foods">
            {({ push, move, remove }) => (
              <>
                {props.values.foods.map((e, index) => {

                  const removeField = () => {
                    let result = confirm('Sei sicuro?');
                    if (result) {
                      props.setFieldValue(`foods.${index}._destroy`, 1);
                      if (e.id == null) {
                        remove(index)
                      }
                    }
                  };

                  const moveUp = () => {

                    if (index > 0) {
                      move(index, index-1)
                      props.values.foods.map((e, index) => {
                        props.setFieldValue(`foods.${index}.position`, index+1);
                      })
                    }

                  }

                  const moveDown = () => {

                    if (index + 1 < props.values.foods.length) {
                      move(index, index+1)
                      props.values.foods.map((e, index) => {
                        props.setFieldValue(`foods.${index}.position`, index+1);
                      })
                    }

                  }

                  if (!!e._destroy) {
                    return null;
                  }

                  return (
                    <NutritionalFoodForm
                      key={e.id || `new_${index}`}
                      setFieldValue={props.setFieldValue}
                      values={e}
                      remove={removeField}
                      {...{ index, moveUp, moveDown, foodCategories, foodOptions }}
                    />
                  );
                })}

                <AddButton onClick={() => push({ _destroy: 0 })} />
              </>
            )}
          </FieldArray>

          <h1 className="font-bold text-xl py-2">Supplementazione</h1>

          <FieldArray name="supplements">
            {({ push, remove }) => (
              <>
                {props.values.supplements?.map((e, index) => {
                  const removeField = () => {
                    let result = confirm('Sei sicuro?');
                    if (result) {
                      props.setFieldValue(`supplements.${index}._destroy`, 1);
                      if (e.id == null) {
                        remove(index)
                      }
                    }
                  };

                  if (!!e._destroy) {
                    return null;
                  }

                  return (
                    <SupplementFields
                      key={e.id || `new_${index}`}
                      setFieldValue={props.setFieldValue}
                      values={e}
                      remove={removeField}
                      {...{ index, supplementBrands, supplementOptions }}
                    />
                  );
                })}

                <AddButton onClick={() => push({ _destroy: 0, amount: '0' })} />

              </>
            )}
          </FieldArray>
        </form>
      )}
    </Formik>
  );
}
