import { Controller } from "stimulus"
import TomSelect from "tom-select"

export default class extends Controller {

  static values = { url: String }

  connect() {

    const config = {
      plugins: ['remove_button'],
      persist: false,
      createOnBlur: true,
      create: true,
      onItemAdd: function(){
  			this.setTextboxValue('');
  			this.refreshOptions();
  		},
    }

    new TomSelect(this.element, config);

  }

}
