import React from 'react';
import Popup from 'reactjs-popup';

export default function AddExercise({ push, position }) {

  const arrowStyle = { color: '#495172' };

  return (
    <Popup
      {...{ arrowStyle }}
      trigger={
        <div className="btn text-gray-600">
          <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="#495172">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M12 6v6m0 0v6m0-6h6m-6 0H6"
            />
          </svg>
          Esercizio
        </div>
      }
      position="top center"
    >
      {(close) => (
        <div className="p-4 text-center bg-p2-500 shadow rounded-xl">
          <p className="pb-2 text-sm text-white">Segli il tipo di esercizio</p>
          <div className="flex space-x-4">
            <button
              className="btn"
              type="button"
              onClick={() => {
                close();
                push({ exerciseType: 'standard', position });
              }}
            >
              Normale
            </button>

            <button
              className="btn"
              type="button"
              onClick={() => {
                close();
                push({ exerciseType: 'special', childrenAttributes: [], position });
              }}
            >
              Speciale
            </button>
          </div>
        </div>
      )}
    </Popup>
  );

}
