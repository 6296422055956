import React from 'react';
import cx from 'classnames';
import { TimetokenUtils } from '@pubnub/chat';
import it from 'date-fns/locale/it';
import { format } from 'date-fns';


export const getNameInitials = (name) => {
  if (!name || !name.length) return "";
  const nameSplit = name.split(" ");
  const initials =
    nameSplit.length == 1 ? nameSplit[0].substring(0, 2) : nameSplit[0][0] + nameSplit[1][0];
  return initials.toUpperCase();
};

export const getPredefinedColor = (uuid) => {
  if (!uuid || !uuid.length) return;
  const colors = ["#80deea", "#9fa7df", "#aed581", "#ce93d8", "#ef9a9a", "#ffab91", "#ffe082"];
  const sum = uuid
    .split("")
    .map((c) => c.charCodeAt(0))
    .reduce((a, b) => a + b);
  return colors[sum % colors.length];
};

const getTime = (timetoken) => {
  const date = TimetokenUtils.timetokenToDate(timetoken); 
  return `${date.toLocaleTimeString([], { timeStyle: "short" })} - ${date.toLocaleDateString()}`
};

export function channelRender(props) {

  const { channel, switchChannel, currentChannel, unreadCount } = props;

  const channelActive = channel.id === currentChannel;
  const activeClass = channelActive ? "pn-channel--active" : "";

  let timing = null;
  if (channel.custom?.lastMessageTimetoken) {
    const date = TimetokenUtils.timetokenToDate(channel.custom.lastMessageTimetoken);
    timing = format(date, 'EEEE dd/MM HH:mm', { locale: it });
  }

  console.log('channel', channel)

  return (
    <div
      key={channel.id}
      className={`pn-channel ${activeClass}`}
      onClick={() => switchChannel(channel)}
    >
      <div className="pn-channel__title flex justify-between items-center">
        <p className="pn-channel__name flex items-center gap-1">
          { channel.custom?.priority ? (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><circle cx="12" cy="19" r="2" fill="#f59e0b"/><path fill="#f59e0b" d="M12 3c-1.1 0-2 .9-2 2v8c0 1.1.9 2 2 2s2-.9 2-2V5c0-1.1-.9-2-2-2"/></svg>
          ) : '' }
          {channel.name || channel.id} {timing ? ` (${timing})` : ''}
        </p>
        <div className={cx("text-xs p-1 px-2 rounded-full", { ['bg-green-100 text-green-800']: unreadCount > 0 })}>{unreadCount}</div>
      </div>
    </div>
  );
}

export function messageRenderer({ message: envelope, user, time, isOwn, editedText }, deleteMessage, is_supervisor) {

  const uuid = envelope.uuid

  return (
    <>
      <div className="pn-msg__avatar" style={{ backgroundColor: getPredefinedColor(uuid) }}>
        {getNameInitials(user?.name || uuid)}
      </div>

      <div className="pn-msg__main">
        <div className="pn-msg__content">
          <div className="pn-msg__title">
            <span className="pn-msg__author">{user?.name || uuid}</span>
            <span className="pn-msg__time">{getTime(envelope.timetoken)}</span>
            {is_supervisor && <span className="pn-msg__delete" onClick={() => deleteMessage(envelope.timetoken)}>Elimina</span>}
          </div>
          <div className="pn-msg__bubble">{envelope?.message.text}</div>
        </div>
      </div>
    </>
  );

}
