import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import ClipLoader from "react-spinners/ClipLoader";

import { NUTRITIONAL_PLAN } from "graphql/queries";
import { UPDATE_NUTRITIONAL_PLAN } from "graphql/mutations";

import NotesDrawer from "./NotesDrawer";
export default function Form({ nutritionalPlanId }) {

  const { loading, data, error } = useQuery(NUTRITIONAL_PLAN, { variables: { id: nutritionalPlanId } });

  const [updateNutritionaPlan] = useMutation(UPDATE_NUTRITIONAL_PLAN);

  const [hasNotes, setHasPression] = useState("");

  const [open, setOpen] = useState(false);
  {
    /* Check if nutritional plan has already notes */
  }
  useEffect(() => {
    setHasPression(data?.nutritionalPlan?.notes.length > 0 ? true : false);
  }, [data]);
  {
    /* Toogle drawer menu */
  }
  const OpenDrawer = () => {
    setOpen(!open);
  };

  if (loading) {
    return <ClipLoader/>;
  }
  if (error) return `Error! ${error}`;

  const { notes } = data.nutritionalPlan;

  return (
    <>
      {hasNotes ? (
        <section className="rounded-lg bg-white overflow-hidden shadow p-6 flex flex-col">
          <h1 className="text-lg font-bold text-p2-600 self-start mb-2">Note e Info <span className="text-xs font-normal">(visibili nel PDF)</span></h1>

          <div className="flex flex-col space-y-8">
            {notes?.map((p, index) => (
              <div key={p.id} className="mt-2 text-sm font-light text-p2-500">
                <h1 className="text-xs font-bold text-p2-500">Titolo</h1>
                {p.title}
                <h1 className="text-xs font-bold text-p2-500 pt-2">Contenuto</h1>
                {p.description}
              </div>
            ))}
          </div>
          <button className="btn mt-4" onClick={() => setOpen(true)}>
            Modifica
          </button>
        </section>
      ) : (
        <section className="rounded-lg border-dashed border-p2-400 border-2 overflow-hidden p-6">
          <h1 className="text-lg font-bold text-p1-500 pb-2">Note e Info</h1>
          <p className="text-p2-500 pb-1">Nessuna nota presente</p>
          <p
            onClick={() => setOpen(true)}
            className="pb-1 underline cursor-pointer text-p1-600"
          >
            Impostale ora
          </p>
        </section>
      )}
      {/* Drawer menu */}
      {open ? (
        <NotesDrawer direction="col" nutritionalPlanId={nutritionalPlanId} open={open} onClick={OpenDrawer} />
      ) : null}
    </>
  );
}
