import React, { Fragment, useState, useMemo } from "react";
import { Formik, FieldArray } from "formik";

import Textarea from "@components/fields/Textarea";
import AutoSave from '@components/AutoSave';

import AddButton from './../AddButton';

import SupplementFields from './../shared/SupplementFields';

export default function SupplementsForm({ data, updateNutritionalDay, nutritionalDayId, supplementBrands, supplementOptions }) {

  const initialValues = {
    notes: data.nutritionalDay.notes || '',
    macroCarbohydrates: data.nutritionalDay.macroCarbohydrates || 0,
    macroProteins: data.nutritionalDay.macroProteins || 0,
    macroFats: data.nutritionalDay.macroFats || 0,
    supplements: data.nutritionalDay.supplements.map(e => ({
      id: e.id,
      supplementId: e.supplementId,
      nutritionalMealId: e.nutritionalMealId,
      nutritionalDayId: e.nutritionalDayId,
      brand: e.brand || '',
      amount: e.amount || '',
      unitMeasure: e.unitMeasure || '',
      _destroy: 0,
    })) || []
  }

  const onSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    updateNutritionalDay({
      variables: {
        id: nutritionalDayId,
        input: {
          notes: values.notes,
          supplementsAttributes: values.supplements.map((w) => ({
            id: w.id,
            brand: w.brand,
            supplementId: w.supplementId,
            nutritionalMealId: w.nutritionalMealId,
            nutritionalDayId: w.nutritionalDayId,
            amount: w.amount || 0,
            unitMeasure: w.unitMeasure,
            _destroy: w._destroy,
          })) || []
        }
      },
      onCompleted: () => {
        setSubmitting(false);
      }
    });

  };

  return (
    <Formik
      enableReinitialize
      validateOnMount
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {(props) => (
        <form onSubmit={props.handleSubmit} className="form">

          <div className="mt-4 flex justify-end">

            {props.dirty && (
              <span className="text-white bg-yellow-400 px-3 py-2 rounded-md shadow-md mr-4">
                Modifiche non salvate!
              </span>
            )}

            <div className="btn btn-dark" onClick={() => { !props.isSubmitting && props.handleSubmit() } }>{props.isSubmitting ? 'Salvataggio...' : 'Salva'}</div>

          </div>

          <Textarea name={`notes`} label="Note" />

          <h1 className="font-bold text-xl py-8">Supplementazione</h1>

          <FieldArray name="supplements">
            {({ push, remove }) => (
              <Fragment>

                {props.values.supplements?.map((e, index) => {
                  const removeField = () => {
                    let result = confirm('Sei sicuro?');
                    if (result) {
                      props.setFieldValue(`supplements.${index}._destroy`, 1);
                      if (e.id == null) {
                        remove(index)
                      }
                    }
                  };
                  if (!!e._destroy) {
                    return null;
                  }
                  return (
                    <SupplementFields
                      key={e.id || `new_${index}`}
                      setFieldValue={props.setFieldValue}
                      values={e}
                      remove={removeField}
                      {...{ index, supplementBrands, supplementOptions }}
                    />
                  );
                })}

                <AddButton onClick={() => push({ _destroy: 0, amount: '0' })} />

              </Fragment>
            )}
          </FieldArray>

        </form>
      )}
    </Formik>
  );

}
