import React, { useState, useMemo } from 'react';

import Select from '@components/fields/Select';
import InputField from '@components/fields/Input';
import Textarea from '@components/fields/Textarea';

export default function ExerciseChild({
  e,
  index,
  cIndex,
  remove,
  exerciseCategories, exerciseOptions,
  setFieldValue,
  ...props
}) {
  const [showBox, setShowBox] = useState(false);

  const [hiddenField, setHiddenField] = useState(false);

  return (
    <div className="w-full bg-p3-500">
      <div className="flex">
        <div className="w-1/2 flex flex-col p-6">
          <div>
            <Select
              name={`exercises.${index}.childrenAttributes.${cIndex}.exerciseId`}
              label="Esercizio"
              options={exerciseOptions}
              onChange={(value) => {
                setFieldValue(`exercises.${index}.childrenAttributes.${cIndex}.exerciseId`, value.value);
              }}
            />
            <div
              className="mt-6 flex items-center space-x-2 cursor-pointer underline text-sm text-gray-400"
              onClick={() => setShowBox(true)}
            >
              {!showBox && (
                <>
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M8 4H6a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-2m-4-1v8m0 0l3-3m-3 3L9 8m-5 5h2.586a1 1 0 01.707.293l2.414 2.414a1 1 0 00.707.293h3.172a1 1 0 00.707-.293l2.414-2.414a1 1 0 01.707-.293H20"
                      />
                    </svg>
                  </span>
                  <span>Aggiungi nota</span>
                </>
              )}
            </div>
            {showBox && (
              <div className="mt-4 flex items-center">
                <Textarea
                  name={`exercises.${index}.childrenAttributes.${cIndex}.notes`}
                  label="Note"
                />
              </div>
            )}
          </div>
        </div>
        <div className="w-1/2 p-6 flex flex-col relative">
          <div>
            <div>
              <div className="flex space-x-4 items-center">
                <InputField
                  name={`exercises.${index}.childrenAttributes.${cIndex}.repetition`}
                  label="Ripetizioni"
                  placeholder="Es. 4"
                  type="text"
                />
                <InputField
                  name={`exercises.${index}.childrenAttributes.${cIndex}.weightAdvisor`}
                  label="Peso Indicato"
                  placeholder="Es. 10"
                  type="text"
                  hint="kg"
                />
              </div>
              <div
                className="absolute top-0 right-0 flex justify-center items-center p-2 transform duration-500 hover:rotate-90 transition-all"
                onClick={remove}
              >
                <div className="p-2 cursor-pointer">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
