import React, { useMemo } from 'react';
import { Formik, FieldArray } from 'formik';
import uniq from 'lodash.uniq';
import ClipLoader from "react-spinners/ClipLoader";

import { workoutOptions } from '../../components/options';
import Select from '../../components/fields/Select';
import InputField from '../../components/fields/Input';
import AutoSave from '../../components/AutoSave';

import SpecialExercise from './SpecialExercise';
import Exercise from './Exercise';
import AddExercise from './AddExercise';

import { useQuery, useMutation } from '@apollo/client';

import { WORKOUT } from 'graphql/queries';
import { UPDATE_WORKOUT } from 'graphql/mutations';

export default function Form({ url, workoutId, exercisesList }) {

  const exerciseCategories = useMemo(() =>
    uniq(exercisesList.map((e) => e.category))
      .sort()
      .map((c) => ({ label: c, value: c }))
    , [exercisesList]);

  const exerciseOptions = useMemo(() => exercisesList.map((e) => ({
    label: e.label,
    value: e.id,
    category: e.category,
  })), [exercisesList]);

  const { loading, data } = useQuery(WORKOUT, { variables: { id: workoutId } });

  const [updateWorkout] = useMutation(UPDATE_WORKOUT);

  if (loading) {
    return <ClipLoader/>;
  }

  const onSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    updateWorkout({
      variables: {
        id: workoutId,
        input: {
          category: values.category,
          repetition: values.repetition,
          exercisesAttributes: values.exercises,
        },
      },
      onCompleted: () => {
        setSubmitting(false);
      }
    });
  };

  const initialValues = {
    category: data.workout.category,
    repetition: data.workout.repetition,
    exercises: data.workout.exercises.map((e) => ({
      id: e.id,
      position: e.position,
      exerciseType: e.exerciseType,
      category: e.category,
      tool: e.tool,
      recovery: e.recovery || '',
      weightAdvisor: e.weightAdvisor || '',
      repetition: e.repetition || '',
      series: e.series || '',
      notes: e.notes || '',
      haveProgression: e.haveProgression,
      presetName: e.presetName,
      parentId: e.parentId,
      workoutId: e.workoutId,
      exerciseId: e.exerciseId,
      position: e.position,
      childrenAttributes: e.childrenAttributes.map((a) => ({
        id: a.id,
        exerciseId: a.exerciseId,
        repetition: a.repetition || '',
        weightAdvisor: a.weightAdvisor || '',
        notes: a.notes || '',
      })),
      _destroy: 0,
    })),
  }

  return (
    <Formik
      enableReinitialize
      validateOnMount
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {(props) => (
        <form onSubmit={props.handleSubmit} className="form">

          <div className="flex justify-between py-2">

            {data.workout.category !== "free" && data.workout.category !== "normal" && (
              <div>
                <div className="flex justify-between items-center w-full py-4">
                  <div className="w-56">
                    <InputField name={`repetition`} label="Ripetizioni settimanali" type="text" />
                  </div>
                </div>
              </div>
            )}

            {data.workout.category !== 'pre_workout' && data.workout.category !== 'post_workout' && (
              <div>
                <div className="flex justify-between items-center w-full py-4">
                  <div className="w-56">
                    <Select name={`category`} label="Tipo di Workout" options={workoutOptions} />
                  </div>
                </div>
              </div>
            )}

            <div className="mt-4">

              {props.dirty && (
                <span className="text-white bg-yellow-400 px-3 py-2 rounded-md shadow-md mr-4">
                  Modifiche non salvate!
                </span>
              )}

              <div className="btn btn-dark" onClick={() => { !props.isSubmitting && props.handleSubmit()} }>{props.isSubmitting ? 'Salvataggio...' : 'Salva'}</div>

            </div>

          </div>

          <FieldArray name="exercises">
            {({ push, move, remove }) => (
              <div>
                {props.values.exercises.map((e, index) => {
                  const removeField = () => {
                    let result = confirm('Sei sicuro?');
                    if (result) {
                      props.setFieldValue(`exercises.${index}._destroy`, 1);
                      if (e.id == null) {
                        remove(index)
                      }
                    }
                  };
                  if (!!e._destroy) {
                    return null;
                  }

                  const moveUp = () => {

                    if (index > 0) {
                      move(index, index-1)
                      props.values.exercises.map((e, index) => {
                        props.setFieldValue(`exercises.${index}.position`, index+1);
                      })
                    }

                  }

                  const moveDown = () => {

                    if (index + 1 < props.values.exercises.length) {
                      move(index, index+1)
                      props.values.exercises.map((e, index) => {
                        props.setFieldValue(`exercises.${index}.position`, index+1);
                      })
                    }

                  }

                  if (e.exerciseType == 'special') {
                    return (
                      <SpecialExercise
                        key={`${e.id}`}
                        setFieldValue={props.setFieldValue}
                        presets={data.presets}
                        values={e}
                        dirty={props.dirty}
                        workoutExerciseId={e.id}
                        remove={removeField}
                        {...{ exerciseCategories, exerciseOptions, moveUp, moveDown, index }}
                      />
                    );
                  } else {
                    return (
                      <Exercise
                        key={`${e.id}`}
                        setFieldValue={props.setFieldValue}
                        values={e}
                        dirty={props.dirty}
                        workoutExerciseId={e.id}
                        remove={removeField}
                        {...{ exerciseCategories, exerciseOptions, moveUp, moveDown, index }}
                      />
                    );
                  }
                })}

                <div className="text-center mt-4 mb-8">
                  <AddExercise position={props.values.exercises.length + 1} {...{ push }} />
                </div>

              </div>
            )}
          </FieldArray>
        </form>
      )}
    </Formik>
  );
}
