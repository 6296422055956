import React from "react";
import InputField from "@components/fields/Input";

export default function Progression({ index, remove, ...props }) {
  return (
    <div className="text-gray-900 text-sm font-medium flex justify-center flex-row pt-4">
      <div
        style={{ backgroundColor: "#8990AD" }}
        className=" whitespace-nowrap text-center mt-4 w-8 h-8 rounded-full flex items-center justify-center"
      >
        <p className="text-white mx-4">{index + 1}</p>
      </div>
      <div className={`flex flex-col`}>
        <div className="px-6 pt-2 whitespace-nowrap text-center flex justify-center">
          <div className="flex flex-col items-start">
            <p className="text-left mb-2">Serie</p>
            <InputField name={`progressions.${index}.series`} placeholder="3x10" type="text" />
          </div>
        </div>
        <div className="px-6 pt-2 whitespace-nowrap text-center">
          <p className="text-left mb-2">Carico</p>
          <InputField name={`progressions.${index}.load`} placeholder="5" type="number" hint="kg" />
        </div>
        <div className="px-6 pt-2 whitespace-nowrap text-center">
          <p className="text-left mb-2">Recupero</p>
          <InputField name={`progressions.${index}.recovery`} placeholder="secondi" type="number" hint="Sec" />
        </div>
      </div>
      <div onClick={remove}>
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mt-4 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="#8990AD">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
      </div> 
    </div>
  );
}
