import React from "react";
import { useField } from "formik";

export default function Textarea({ name, ...props }) {
  const [field] = useField(name);

  const { label, ...rest } = props;

  return (
    <div className="mb-4 inline-block w-full">
      <label className="block text-sm font-medium">{label}</label>
      <textarea
        {...(field || '')}
        {...rest}
        className="shadow-sm focus:ring-gray-200 focus:border-gray-200 block w-full sm:text-sm rounded-md border-gray-300 text optional mt-2"
      />
    </div>
  );
}
