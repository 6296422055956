import React, { Fragment, useState, useEffect } from "react";
import PubNub from "pubnub";
import { PubNubProvider, usePubNub } from "pubnub-react";

import ChatWrapper from './Chat';

function App({ uuid, pn_keys, token: rawToken, is_supervisor }) {

  const [token, setToken] = useState(rawToken);

  const pubnub = new PubNub({
    publishKey: pn_keys.publish,
    subscribeKey: pn_keys.subscribe,
    userId: uuid,
    heartbeatInterval: 0
  });

  pubnub.setToken(token)

  const parsedToken = pubnub.parseToken(token)

  //console.log('Token', token, parsedToken);

  return (
    <PubNubProvider client={pubnub}>
      <ChatWrapper {...{ setToken, parsedToken, is_supervisor }} />
    </PubNubProvider>
  )

}

export default App;
