import React from "react";
import InputField from "@components/fields/Input";
import Textarea from "@components/fields/Textarea";

export default function Note({ index, remove }) {

  return (
    <div className="relative text-gray-900 text-sm font-medium pt-4">
      <div className={`flex flex-col pr-4`}>
        <div className="px-6 pt-2 whitespace-nowrap text-center">
          <div className="flex flex-col items-start">
            <p className="text-left mb-2">Titolo</p>
            <InputField name={`notes.${index}.title`} placeholder="Titolo" type="text" />
          </div>
        </div>
        <div className="px-6 pt-2 whitespace-nowrap text-center">
          <div className="flex flex-col items-start">
            <p className="text-left mb-2">Contenuto</p>
            <Textarea name={`notes.${index}.description`} />
          </div>
        </div>
      </div>
      <div className="absolute inset-y-1/2 right-0" onClick={() => remove()}>
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mt-4 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="#86704D">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
      </div>
    </div>
  );
}
