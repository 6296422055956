import React, {useMemo} from "react";
import uniq from 'lodash.uniq';
import { Formik, FieldArray } from "formik";
import InputField from "@components/fields/Input";
import Select from "@components/fields/Select";
import Textarea from "@components/fields/Textarea";
import AutoSave from '@components/AutoSave';
import ClipLoader from "react-spinners/ClipLoader";

import SupplementFields from "./../shared/SupplementFields";
import MacroalimentsVisual from "./../shared/MacroAlimentsVisual";

import AddButton from './../AddButton';

import { dayOptions } from "@components/options"

import { useQuery, useMutation } from '@apollo/client';

import { NUTRITIONAL_DAY } from 'graphql/queries';
import { UPDATE_NUTRITIONAL_DAY } from 'graphql/mutations';

export default function Form({ nutritionalDayId, supplementBrands, supplementOptions }) {

  const { loading, data, error } = useQuery(NUTRITIONAL_DAY, { variables: { id: nutritionalDayId } });

  const [updateNutritionalDay] = useMutation(UPDATE_NUTRITIONAL_DAY);

  const validate = (values) => {

    return {}

  };

  const onSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    updateNutritionalDay({
      variables: {
        id: nutritionalDayId,
        input: {
          category: values.category,
          carbohydrates: values.carbohydrates,
          proteins: values.proteins,
          fats: values.fats,
          sodium: values.sodium,
          water: values.water,
          vegetables: values.vegetables,
          notes: values.notes,
          supplementsAttributes: values.supplements.map(e => ({
            id: e.id,
            supplementId: e.supplementId,
            nutritionalMealId: e.nutritionalMealId,
            nutritionalDayId: e.nutritionalDayId,
            brand: e.brand || '',
            amount: e.amount || '',
            unitMeasure: e.unitMeasure,
            _destroy: e._destroy,
          }))
        }
      },
      onCompleted: () => {
        setSubmitting(false);
      }
    });

  };

  if (loading) { return <ClipLoader /> }

  if (error) return `Error! ${error}`;

  return (
    <Formik
      enableReinitialize
      validateOnMount
      initialValues={{
        category: data.nutritionalDay.category,
        carbohydrates: data.nutritionalDay.carbohydrates,
        proteins: data.nutritionalDay.proteins,
        fats: data.nutritionalDay.fats,
        sodium: data.nutritionalDay.sodium,
        water: data.nutritionalDay.water,
        vegetables: data.nutritionalDay.vegetables,
        notes: data.nutritionalDay.notes,
        supplements: data.nutritionalDay.supplements.map(e => ({
          id: e.id,
          supplementId: e.supplementId,
          nutritionalMealId: e.nutritionalMealId,
          nutritionalDayId: e.nutritionalDayId,
          brand: e.brand || '',
          amount: e.amount || '',
          unitMeasure: e.unitMeasure || '',
          _destroy: 0
        })),
      }}
      validate={validate}
      onSubmit={onSubmit}
    >
      {(props) => (
        <form onSubmit={props.handleSubmit} className="form">

          <div className="float-right pt-6">
            <AutoSave debounceMs={500} />
          </div>

          {(
            data.nutritionalDay.category !== "pre_workout" &&
            data.nutritionalDay.category !== "post_workout" &&
            data.nutritionalDay.category !== "intra_workout") && (
              <div className="pt-24 pb-12">
                <MacroalimentsVisual sumProteins={props.values.proteins} sumCarbohydrates={props.values.carbohydrates} sumFats={props.values.fats} />
              </div>
          )}

          {(data.nutritionalDay.category === "pre_workout" ||
            data.nutritionalDay.category === "post_workout" ||
            data.nutritionalDay.category === "intra_workout") && (
              /* Form Pre Post Workout */
              <>

                <div className="my-4">
                  <Textarea name={`notes`} label="Note" />
                </div>

                <h1 className="font-bold text-xl py-2">Supplementazione</h1>
                  <FieldArray name="supplements">
                    {({ push, remove }) => (
                      <>
                        {props.values.supplements?.map((e, index) => {
                          const removeField = () => {
                            let result = confirm('Sei sicuro?');
                            if (result) {
                              props.setFieldValue(`supplements.${index}._destroy`, 1);
                              if (e.id == null) {
                                remove(index)
                              }
                            }
                          };
                          if (!!e._destroy) {
                            return null;
                          }
                          return (
                            <SupplementFields
                              key={e.id}
                              setFieldValue={props.setFieldValue}
                              values={e}
                              remove={removeField}
                              {...{ index, supplementBrands, supplementOptions }}
                            />
                          );
                        })}

                        <AddButton onClick={() => push({ _destroy: 0, amount: '0' })} />

                      </>
                    )}
                  </FieldArray>
              </>
            )}

          {/* Form Flex Macro Amount */}
          {(data.nutritionalDay.category !== "pre_workout" &&
            data.nutritionalDay.category !== "post_workout" &&
            data.nutritionalDay.category !== "intra_workout") && (
              <>
                <div className="border-b border-gray-200">
                  <div className="w-40">
                    <div className="pt-6 pb-4">
                      <Select
                        name={`category`}
                        label="Tipo di giorno"
                        options={dayOptions}
                      />
                    </div>
                  </div>
                </div>
                <div className="mb-16">
                  <div className="grid grid-cols-5 border-b border-gray-300 pt-4">
                    <p className="col-span-4 flex items-center">Carboidrati</p>
                    <InputField
                      name={`carbohydrates`}
                      label="Quantità"
                      type="number"
                      hint="gr"
                    />
                  </div>
                  <div className="grid grid-cols-5 border-b border-gray-300 pt-4">
                    <p className="col-span-4 flex items-center">Proteine</p>
                    <InputField
                      name={`proteins`}
                      label="Quantità"
                      type="number"
                      hint="gr"
                    />
                  </div>
                  <div className="grid grid-cols-5 border-b border-gray-300 pt-4">
                    <p className="col-span-4 flex items-center">Grassi</p>
                    <InputField
                      name={`fats`}
                      label="Quantità"
                      type="number"
                      hint="gr"
                    />
                  </div>
                  <div className="grid grid-cols-5 border-b border-gray-300 pt-4">
                    <p className="col-span-4 flex items-center">Sale</p>
                    <InputField
                      name={`sodium`}
                      label="Quantità"
                      type="number"
                      hint="gr"
                    />
                  </div>
                  <div className="grid grid-cols-5 border-b border-gray-300 pt-4">
                    <p className="col-span-4 flex items-center">Acqua</p>
                    <InputField
                      name={`water`}
                      label="Quantità"
                      type="number"
                      hint="ml"
                    />
                  </div>
                  <div className="grid grid-cols-5 border-b border-gray-300 pt-4">
                    <p className="col-span-4 flex items-center">Verdure</p>
                    <InputField
                      name={`vegetables`}
                      label="Quantità"
                      type="number"
                      hint="gr"
                    />
                  </div>
                </div>
              </>
            )}

        </form>
      )}
    </Formik>

  );
}
