import React, { useState, useMemo } from "react";
import uniq from 'lodash.uniq';

import apolloClient from '../apollo-client';
import { ApolloProvider } from '@apollo/client';

import NutritionalFlexForm from "./NutritionalFlexForm";
import NutritionalCyclicForm from "./NutritionalCyclicForm";
import TabHeader from "./TabHeader";
import Notes from './../NutritionalPlanNotes';
import NutritionalPlanSupplements from './../NutritionalPlanSupplements';

export default function NutritionalPlanEditor({ category, supplementsList, foodsList, days, ...props}) {

  const [activeNutritionalDay, setActiveNutritionalDay] = useState(false);

  const supplementBrands = useMemo(() =>
    uniq(supplementsList.map((w) => w.brand))
    .sort()
    .map((d) => ({ label: d, value: d }))
  , [supplementsList]);

  const supplementOptions = useMemo(() =>
    supplementsList.map((w) => ({
      label: w.label,
      value: w.id,
      amount: w.amount,
      brand: w.brand
    }))
    , [supplementsList]);

  const foodCategories = useMemo(() =>
    uniq(foodsList.map((e) => e.category))
      .sort()
      .map((c) => ({ label: c, value: c }))
    , [foodsList]);

  const foodOptions = useMemo(() =>
    foodsList.map((e) => ({
      label: e.label,
      value: e.id,
      category: e.category,
      carbohydrates: e.carbohydrates,
      proteins: e.proteins,
      fats: e.fats,
      salt: e.salt,
    }))
    , [foodsList]);

  return (
    <ApolloProvider client={apolloClient}>
      <div className="container mx-auto py-4 flex space-x-8">
        <div className="w-4/6">
          <TabHeader {...props} {...{ setActiveNutritionalDay, activeNutritionalDay }} />
          {category == "flex" && activeNutritionalDay && <NutritionalFlexForm {...{supplementBrands, supplementOptions}} {...props} nutritionalDayId={activeNutritionalDay} />}
          {category == "cyclic" && activeNutritionalDay && <NutritionalCyclicForm {...{supplementBrands, supplementOptions, foodCategories, foodOptions, days}} {...props} nutritionalDayId={activeNutritionalDay} />}
        </div>
        <div className="w-2/6">
          <Notes id={props.nutritionalPlanId}/>
          <div className="mt-8">
            <NutritionalPlanSupplements id={props.nutritionalPlanId} {...{supplementsList}}/>
          </div>
        </div>
      </div>
    </ApolloProvider>
  );
}
