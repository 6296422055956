import React, { useState } from 'react';
import { useField } from 'formik';

export default function InputField({ name, ...props }) {
  const [field, meta, helpers] = useField(name);

  const { label, placeholder, type, hint, ...rest } = props;

  return (
    <div className="mb-4 inline-block w-full">
      <label className="block text-sm font-medium max-w-full">{label}</label>
      <div className="relative mt-2">
        <input
          {...(field || '')}
          {...rest}
          type={type}
          className="shadow-sm focus:outline-none focus:ring focus:ring-gray-300 block w-full sm:text-sm rounded-md border border-gray-300 string optional p-2 mt-2 "
          placeholder={placeholder}
        />
        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
          <span className="text-gray-500 sm:text-sm">{hint}</span>
        </div>
      </div>
    </div>
  );
}
