import React, { useState, useEffect } from "react";
import { Formik, FieldArray } from "formik";
import { useQuery, useMutation } from "@apollo/client";
import ClipLoader from "react-spinners/ClipLoader";

import { WORKOUT_SHEET } from "graphql/queries";
import { UPDATE_WORKOUT_SHEET } from "graphql/mutations";

import Drawer from "./Drawer";

export default function Form({ workoutSheetId }) {

  const { loading, data, error } = useQuery(WORKOUT_SHEET, { variables: { id: workoutSheetId } });

  
  const [updateWorkoutSheet] = useMutation(UPDATE_WORKOUT_SHEET);
  
  const [hasProgresssion, setHasPression] = useState("");

  const [open, setOpen] = useState(false);
  {
    /* Check if the workout has already progression */
  }
  useEffect(() => {
    setHasPression(data?.workoutSheet?.progressions.length > 0 ? true : false);
  }, [data]);
  {
    /* Toogle drawer menu */
  }
  const OpenDrawer = () => {
    setOpen(!open);
  };
  
  if (loading) {
    return <ClipLoader/>;
  }
  if (error) return `Error! ${error}`;
  
  const { progressions } = data.workoutSheet;

  return (
    <>
      {hasProgresssion ? (
        <section className="rounded-lg bg-white overflow-hidden shadow p-6 flex flex-col">
          <h1 className="text-lg font-medium text-p2-600 self-start mb-2">Progressioni</h1>
          <div className="grid grid-cols-4 gap-2 my-3">
            <div className="flex flex-col items-center">
              <h1 className="text-xs font-bold text-p2-500">WEEK</h1>
              {progressions?.map((_, index) => (
                <h1 key={index} className="text-sm font-light text-gray-500 mt-2">
                  {index + 1}
                </h1>
              ))}
            </div>
            <div className="flex flex-col items-center">
              <h1 className="text-xs font-bold text-p2-500">SERIE</h1>
              {progressions?.map((p, index) => (
                <h1 key={index} className="mt-2 text-sm font-light text-p2-500">
                  {p.series}
                </h1>
              ))}
            </div>
            <div className="flex flex-col items-center">
              <h1 className="text-xs font-bold text-p2-500">CARICO</h1>
              {progressions?.map((p, index) => (
                <h1 key={index} className="mt-2 text-sm font-light text-p2-500">
                  {p.load} kg
                </h1>
              ))}
            </div>
            <div className="flex flex-col items-center">
              <h1 className="text-xs font-bold text-p2-500">RECUPERO</h1>
              {progressions?.map((p, index) => (
                <h1 key={index} className="mt-2 font-light text-sm text-p2-500">
                  {p.recovery} sec
                </h1>
              ))}
            </div>
          </div>
          <button className="btn mt-4 w-full" onClick={() => setOpen(true)}>
            Modifica
          </button>
        </section>
      ) : (
        <section className="rounded-lg  border-dashed border-p2-400 border-2 overflow-hidden p-6">
          <h1 className="text-lg font-bold text-p1-500 pb-2">Progressioni</h1>
          <p className="   text-p2-500 pb-1">Nessuna progressione presente</p>
          <p
            onClick={() => setOpen(true)}
            className="pb-1 underline cursor-pointer text-p1-600"
          >
            Impostale ora
          </p>
        </section>
      )}
      {/* Drawer menu */}
      {open ? (
        <Drawer direction="col" workoutSheetId={workoutSheetId} open={open} onClick={OpenDrawer} />
      ) : null}
    </>
  );
}
