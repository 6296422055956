import React, { useState, useContext } from "react";
import LoadingContext from "../../LoadingContext";
import cx from 'classnames';
import ClipLoader from "react-spinners/ClipLoader";

import NutritionalMealForm from "./NutritionalMealForm";
import SupplementsForm from './SupplementsForm';
import CopyFromDay from './CopyFromDay';

import MacroalimentsVisual from "./../shared/MacroAlimentsVisual";

import { useQuery, useMutation } from '@apollo/client';

import { NUTRITIONAL_DAY } from 'graphql/queries';
import { UPDATE_NUTRITIONAL_DAY } from 'graphql/mutations';

export default function Form({ url, nutritionalPlanId, nutritionalDayId, setFieldValue, supplementBrands, supplementOptions, foodCategories, foodOptions, days, ...props }) {
  const [activeMeal, setActiveMeal] = useState(false);
  const [loadingState, setLoadingState] = useState(false);

  const { loading, data, error } = useQuery(NUTRITIONAL_DAY, { variables: { id: nutritionalDayId } });

  const [updateNutritionalDay] = useMutation(UPDATE_NUTRITIONAL_DAY);

  if (loading) { return <ClipLoader /> }

  if (error) return `Error! ${error}`;

  return (
    <LoadingContext.Provider value={{ loadingState, setLoadingState }}>

      <div>
        {data.nutritionalDay.category !== "default" && (
          <SupplementsForm {...{ data, updateNutritionalDay, nutritionalDayId, supplementBrands, supplementOptions }} />
          )}
        {(data.nutritionalDay.category === "default") && (
          (!loadingState && (
            <>
            <CopyFromDay  {...props} {...{days}} nutritionalDayId={data.nutritionalDay.id} />
            <div className="pt-12 pb-12">
              <MacroalimentsVisual
                sumProteins={data.nutritionalDay.macroProteins}
                sumCarbohydrates={data.nutritionalDay.macroCarbohydrates}
                sumFats={data.nutritionalDay.macroFats}
              />
            </div>

            <div className="flex my-4">
              <ol className="flex mx-auto space-x-4">
                {data.nutritionalDay.meals.map((w) => (
                  <li
                    key={w.id}
                    className={cx('px-4 py-2 bg-p2-400 rounded-lg cursor-pointer text-center', {
                      'bg-p2-500 rounded-lg text-white': activeMeal == w.id,
                    })}
                    onClick={() => setActiveMeal(w.id)}
                  >
                    <div className="flex">
                      <span>{w.name}</span>
                    </div>
                  </li>
                ))}
              </ol>
            </div>

            {activeMeal && <NutritionalMealForm {...{ supplementBrands, supplementOptions, foodCategories, foodOptions, nutritionalDayId }} {...props} mealId={activeMeal} />}
          </>
          ))
        )}
      </div>
    </LoadingContext.Provider>
  );
}
