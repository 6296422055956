import React from 'react';
import { useField } from 'formik';
import Select, { components } from 'react-select';

const Input = props => (
  <components.Input
     {...props}
     inputClassName="outline-none border-none shadow-none focus:ring-transparent"
  />
)

//Theme
const theme = {
  theme: (theme) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary: '#E6E6E6',
      primary25: '#E6E6E6',
      primary50: '#E9E9E9',
    },
  }),
};
//Style
const customStyles = {
  option: (provided, state) => ({
    ...provided,
    fontSize: '14px',
    padding: '6px 8px',
    backgroundColor: state.isSelected ? '#E5E7EB' : 'white',
    color: state.isSelected ? 'black' : '#6B7280',
    '&:hover': {
      backgroundColor: '#F3F4F6',
    },
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    color: state.isSelected ? '#6B7280' : '#6B7280',
    '&:hover': {
      borderColor: '#6B7280',
    },
  }),
  indicatorSeparator: (base) => ({
    ...base,
    backgroundColor: 'white',
  }),
  control: (provider, state) => ({
    ...provider,
    fontSize: '14px',
    display: 'flex',
    borderRadius: '5px',
    border: '1px solid #d1d5dbd9',
    marginTop: '8px',
    '&:hover': {
      borderColor: '1px solid #d1d5dbd9',
    },
    boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
  }),
};

function SelectField({ name, label, options, ...props }) {
  const { isDisabled } = props;

  const [field, meta, { setValue, setTouched }] = useField(name);

  const onChange = (option) => {
    setValue(option.value);
  };

  return (
    <div>
      <label className="label-question mb-8 text-sm font-medium">{label}</label>
      <Select
        {...props}
        onChange={props.onChange || onChange}
        onBlur={setTouched}
        styles={customStyles}
        theme={theme.theme}
        placeholder="Seleziona..."
        options={options}
        isDisabled={isDisabled}
        value={(options && options.find((o) => o.value === field.value)) || null}
        getOptionValue={(option) => option.label}
        components={{ Input }}
      />
    </div>
  );
}

export default SelectField;
