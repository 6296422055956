import React, { Fragment } from 'react';
import { FieldArray } from 'formik';

import Select from '@components/fields/Select';
import InputField from '@components/fields/Input';
import { foodUnitOptions } from '@components/options';
import { AddIcon } from '@components/Icons';

import RemoveButton from '@components/RemoveButton';

import FoodChild from './FoodChild';

export default function NutritionalFoodForm(props) {

  const {
    index,
    remove,
    foodCategories,
    foodOptions,
    setFieldValue,
    values,
  } = props;

  return (
    <div className="my-6 p-4 bg-white rounded-lg relative">

      <RemoveButton onClick={remove} />

      <div className="absolute top-0 left-0 p-2 flex">
        <div className="cursor-pointer mr-1" onClick={() => props.moveUp() }>&#x25B2;</div>
        <div className="cursor-pointer" onClick={() => props.moveDown() }>&#x25BC;</div>
      </div>

      <div className="grid grid-cols-2 gap-2 space-x-8 pt-8">
        <div>
          <Select
            name={`foods.${index}.category`}
            label="Categoria"
            options={foodCategories}
            onChange={(value) => {
              setFieldValue(`foods.${index}.category`, value.value);
            }}
            />
          
          <div className="pt-3">
            <Select
              name={`foods.${index}.foodId`}
              label="Alimento"
              options={values && foodOptions.filter((value) => value.category === values.category)}
              onChange={(value) => {
                setFieldValue(`foods.${index}.foodId`, value.value);
                setFieldValue(`foods.${index}.carbohydrates`, value.carbohydrates);
                setFieldValue(`foods.${index}.proteins`, value.proteins);
                setFieldValue(`foods.${index}.fats`, value.fats);
                setFieldValue(`foods.${index}.salt`, value.salt);
              }}
            />
          </div>
        </div>
        <div>
          <div className="flex space-x-4">
            <div className="w-1/2 mt-1">
              <InputField name={`foods.${index}.amount`} label="Quantità" type="number" />
            </div>
            <div className="w-1/2">
              <Select name={`foods.${index}.unitMeasure`} label="Unità" options={foodUnitOptions} />
            </div>
          </div>
          <div className="flex space-x-4">
            <div className="w-1/2">
              <InputField name={`foods.${index}.spanAmount`} label="Quantità Span." type="number" />
            </div>
            <div className="w-1/2">
              <InputField name={`foods.${index}.unitSpanMeasure`} label="Unità Span." type="string" />
            </div>
          </div>
        </div>
      </div>

      <div className="mt-2">
        <FieldArray name={`foods.${index}.childrenAttributes`}>
          {({ push, move, remove }) => (
            <Fragment>
              {values.childrenAttributes?.map((w, fIndex) => {

                const removeField = () => {
                  let result = confirm('Sei sicuro?');
                  if (result) {
                    setFieldValue(`foods.${index}.childrenAttributes.${fIndex}._destroy`, 1);
                    if (w.id == null) {
                      remove(fIndex)
                    }
                  }
                }

                if (!!w._destroy) {
                  return null;
                }

                return (
                  <FoodChild
                    key={`${index}_fc_${w.id}` || `${index}_fcn_${fIndex}`}
                    values={w}
                    remove={removeField}
                    {...{
                      foodCategories,
                      foodOptions,
                      index,
                      fIndex,
                      setFieldValue,
                    }}
                  />
                );
              })}
              <div className="py-4 bg-white rounded-b-lg">
                <div
                  className="flex items-center cursor-pointer text-gray-500"
                  onClick={() => push({ _destroy: 0 })}
                >
                  <div className="mr-2 border-2 border-gray-500">
                    <AddIcon />
                  </div>
                  <p className="underline text-sm">Alternativa</p>
                </div>
              </div>
            </Fragment>
          )}
        </FieldArray>
      </div>

    </div>
  );
}
