import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ['link']

  connect() {

    this.linkTargets.map(t => {

      const re = new RegExp(t.dataset.activeLinkUrlValue);

      if (window.location.pathname.match(re)) {
        t.classList.add("active");
      } else {
        t.classList.remove("active");
      }

    })

  }

}
