import { gql } from '@apollo/client';

import { EXERCISE_FIELDS, MEAL_FIELDS } from './fragments';

export const WORKOUT_EXERCISE = gql`
  query WorkoutExercise($id: ID!) {
    workoutExercise(id: $id) {
      id
      exerciseInfos {
        id
        weights
        efforts
      }
    }
  }
`;

export const WORKOUT_SHEET = gql`
  query WorkoutSheet($id: ID!) {
    workoutSheet(id: $id) {
      id
      name
      objective
      weeklyWorkout
      state
      workouts {
        id
        name
        category
        repetition
      }
      progressions {
        id
        series
        load
        recovery
      }
    }
  }
`;

export const WORKOUT = gql`
  ${EXERCISE_FIELDS}
  query Workout($id: ID!) {
    workout(id: $id) {
      id
      name
      category
      repetition
      exercises {
        ...exerciseFields
      }
    }
    presets {
      id
      name
      series
      recovery
      note
    }
  }
`;

export const NUTRITIONAL_PLAN = gql`
  query NutritionalPlan($id: ID!) {
    nutritionalPlan(id: $id) {
      id
      name
      objective
      category
      cyclization
      state
      started
      notes {
        id
        title
        description
      }
      planSupplements {
        id
        nutritionalPlanId
        supplementId
        mealName
        name
        brand
        amount
        unitMeasure
      }
      calendarDays {
        date
        type
      }
      days {
        id
        name
        category
      }
    }
  }
`;

export const MACRO_NUTRITIONAL_DAY = gql`
  query MacroNutritionalDay($id: ID!) {
    nutritionalDay(id: $id) {
      id
      macroCarbohydrates
      macroProteins
      macroFats
    }
  }
`

export const NUTRITIONAL_DAY = gql`
  ${MEAL_FIELDS}
  query NutritionalDay($id: ID!) {
    nutritionalDay(id: $id) {
      id
      name
      category
      carbohydrates
      proteins
      fats
      sodium
      water
      vegetables
      macroCarbohydrates
      macroProteins
      macroFats
      notes
      meals {
        ...mealFields
      }
      supplements {
        id
        nutritionalDayId
        nutritionalMealId
        supplementId
        brand
        amount
        unitMeasure
      }
    }
  }
`;

export const NUTRITIONAL_MEAL = gql`
  ${MEAL_FIELDS}
  query NutritionalMeal($id: ID!) {
    nutritionalMeal(id: $id) {
      ...mealFields
      supplements {
        id
        nutritionalDayId
        nutritionalMealId
        supplementId
        brand
        amount
        unitMeasure
      }
    }
    supplementsList {
      id
      brand
      name
      amount
    }
  }
`;

export const FOODS_LIST = gql`
  query FoodsList {
    foodsList {
      id
      name
      category
      carbohydrates
      proteins
      fats
      salt
    }
  }
`;

export const NEWS_FEEDS = gql`
  query NewsFeeds {
    newsFeeds {
      id
      title
      body
      publishedAt
      ctaLabel
      ctaValue
      read
      important
    }
  }
`;

export const PAGES = gql`
  query Pages($key: String!) {
    pages(key: $key) {
      id
      title
      content
      ctaLabel
      ctaValue
      key
    }
  }
`;
