import React, { useState } from "react";
import cx from 'classnames';
import Popup from 'reactjs-popup';
import ClipLoader from "react-spinners/ClipLoader";

import { useQuery, useMutation } from '@apollo/client';

import { WORKOUT_SHEET } from 'graphql/queries';
import { UPDATE_WORKOUT, CREATE_WORKOUT, DELETE_WORKOUT } from 'graphql/mutations';

export default function TabHeader({ workoutSheetId, activeWorkout, setActiveWorkout }) {

  const [hover, setHover] = useState(false);

  const [editing, setEditing] = useState(false);

  const { loading, data, error } = useQuery(WORKOUT_SHEET, {
    variables: { id: workoutSheetId },
    onCompleted: (data) => {
      if (data.workoutSheet.workouts[0] && !activeWorkout) { setActiveWorkout(data.workoutSheet.workouts[0].id) }
    }
  });

  const [updateWorkout] = useMutation(UPDATE_WORKOUT);

  const [deleteWorkout] = useMutation(DELETE_WORKOUT,{
    update(cache, { data: { deleteWorkout } }) {
      const identify = cache.identify({ deleteWorkout });
      cache.evict({ id: identify });
      cache.gc();
    },
    refetchQueries: [WORKOUT_SHEET]
  });

  const [createWorkout] = useMutation(CREATE_WORKOUT, {
    variables: { id: workoutSheetId },
    onCompleted: ( data ) => {

      setActiveWorkout( data.createWorkout.id )

    }
  });

  if (loading) { return <ClipLoader/> }

  if (error) return `Error! ${error}`;

  const remove = (id) => {
    let result = confirm("Sei sicuro?");
    if(result){
      deleteWorkout({
        variables: {
          id
        }
      })
    }
  }

  const update = (id, value) => {

    updateWorkout({
      variables: { id, input: { name: value } },
      //optimisticResponse: {
        //updateWorkout: {
          //id,
          //name: value,
          //__typename: "Workout"
        //}
      //}
    });

    setEditing(false)

  }

  return (
    <div className="border-b-2 border-gray-300 mt-4">
      <ol className="flex overflow-x-auto items-center">
        {data.workoutSheet.workouts.map(w => (
          <li key={w.id} className={cx('pb-2 cursor-pointer mr-4', { 'border-b-4 border-p1-600': activeWorkout === w.id })}>
            {editing == w.id && (w.category == "normal" || w.category == "free") ? (
              <input
                className="shadow-none animate-pulse border-0 outline-none appearance-none bg-transparent italic text-p2-600 font-medium "
                type="string"
                defaultValue={w.name}
                onBlur={(e) => update(w.id, e.target.value)}
              />
            ) : (
              <div
                className="flex"
                onMouseEnter={() => setHover(w.id)}
                onMouseLeave={() => setHover(false)}
                onClick={() => setActiveWorkout(w.id)}
              >
                <span className="w-max">
                  {w.name}
                </span>
                <div>
                  {hover === w.id && (w.category == "normal" || w.category == "free") && (
                    <>
                      <Popup trigger={
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="#86704D">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z" />
                        </svg>
                      } position="top center">
                        {close => (
                          <div className="py-2 text-center bg-p2-500 shadow rounded-xl">
                            <p onClick={ () => setEditing(w.id) } className="text-sm text-white cursor-pointer py-2 px-4 hover:bg-gray-500">Modifica</p>
                            <p onClick={ () => remove(w.id) } className="text-sm text-white cursor-pointer py-2 px-4 hover:bg-gray-500">Elimina</p>
                          </div>
                        )}
                      </Popup>
                    </>
                  )}
                </div>
              </div>
            )}
          </li>
        ))}

        <li className="mb-2 mr-2" onClick={() => createWorkout()}>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="#86704D">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
          </svg>
        </li>

      </ol>
    </div>
  );
}

