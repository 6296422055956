// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js or *_controller.ts.

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers";
import Flatpickr from "stimulus-flatpickr"
import Reveal from "stimulus-reveal-controller"
import InputMask from "./input_mask_controller"
import FlashNotice from "./flash-notice_controller"
import TomSelect from "./tom-select"
import QuestionsChartController from "./questions_chart_controller"
import Autosave from 'stimulus-rails-autosave'

require("flatpickr/dist/flatpickr.css")


const application = Application.start();
const context = require.context("controllers", true, /_controller\.(js|ts)$/);
application.load(definitionsFromContext(context));

application.register("reveal", Reveal);
application.register('flatpickr', Flatpickr)
application.register('inputmask', InputMask)
application.register('flash-notice', FlashNotice)
application.register('tom-select', TomSelect)
application.register("questions-chart", QuestionsChartController)
application.register('autosave', Autosave)