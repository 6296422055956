import React, { useState } from "react";

import { ApolloProvider } from '@apollo/client';

import TabHeader from "./TabHeader";
import WorkoutForm from "./WorkoutForm";
import ProgressionForm from './../ProgressionForm';

import apolloClient from '../apollo-client';

export default function WorkoutSheetEditor(props) {

  const [activeWorkout, setActiveWorkout] = useState(false);

  return (
    <div className="container mx-auto py-4 flex space-x-8">
      <div className="w-4/6">
        <ApolloProvider client={apolloClient}>
          <TabHeader {...props} {...{ setActiveWorkout, activeWorkout }} />
          {activeWorkout && <WorkoutForm exercisesList={props.exercisesList} {...props} workoutId={activeWorkout} />}
        </ApolloProvider>
        </div>
        <div className="w-2/6">
          <ProgressionForm workoutSheetId={props.workoutSheetId} />
        </div>
    </div>
  );
}
