import React, { useState, useMemo } from 'react';

import { FieldArray } from 'formik';
import Select from '@components/fields/Select';
import InputField from '@components/fields/Input';
import Textarea from '@components/fields/Textarea';

import ExerciseChild from './ExerciseChild';
import ExerciseStats from './../ExerciseStats';
import RemoveButton from '@components/RemoveButton';

export default function SpecialExercise({
  index,
  remove,
  presets,
  moveWorkoutExercise,
  exerciseCategories, exerciseOptions,
  setFieldValue,
  workoutExerciseId,
  values,
  ...props
}) {
  const [showStats, setShowStats] = useState(false);

  const presetOptions = useMemo(() => presets.map((p) => ({ label: p.name, value: p.id, meta: p })), [presets]);

  return (
    <div className="relative rounded-lg bg-white shadow my-8">

      {showStats ? <ExerciseStats {...{ workoutExerciseId, setShowStats }} /> : null}

      <div className="flex absolute top-0 left-0 p-4">
        <div className="cursor-pointer mr-1" onClick={() => props.moveUp() }>&#x25B2;</div>
        <div className="cursor-pointer" onClick={() => props.moveDown()}>&#x25BC;</div>
      </div>

      <RemoveButton onClick={remove} />

      <div className="flex pt-12">
        <div className="w-2/4 flex space-x-4 items-center px-4 pb-4">
          <div className="w-2/4 -mt-4">
            <Select
              name={`exercises.${index}.presetName`}
              label="Preset"
              options={presetOptions}
              onChange={(value) => {
                setFieldValue(`exercises.${index}.presetName`, value.meta.name);
                setFieldValue(`exercises.${index}.series`, value.meta.series);
                setFieldValue(`exercises.${index}.recovery`, value.meta.recovery);
                setFieldValue(`exercises.${index}.notes`, value.meta.notes);
              }}
            />
          </div>
          <div className="w-2/4">
            <InputField name={`exercises.${index}.presetName`} label="Nome Preset" type="text" />
          </div>
        </div>
        <div className="w-2/4 px-6 py-2 flex flex-col">
          <div>
            <div className="flex space-x-4 items-center">
              <InputField
                name={`exercises.${index}.series`}
                label="Serie"
                placeholder="Es. 2x10"
                type="text"
              />
              <InputField
                name={`exercises.${index}.recovery`}
                label="Recupero"
                type="text"
                hint="Sec"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="px-4 pb-4">
        <div className="flex items-center space-x-12">
          <div className="w-1/2">
            <Textarea name={`exercises.${index}.notes`} label="Note" />
          </div>
          <div className="w-1/2">
            <p
              className="cursor-pointer underline text-sm text-p1-600"
              onClick={() => setShowStats(!showStats)}
            >
              {!showStats ? 'Mostra Statistiche' : null}
            </p>
          </div>
        </div>
      </div>

      <FieldArray name={`exercises.${index}.childrenAttributes`}>
        {({ push, remove }) => (
          <>
            {values.exerciseType == 'special' &&
              values.childrenAttributes.map((e, cIndex) => {
                const removeField = () => {
                  let result = confirm('Sei sicuro?');
                  if (result) {
                    setFieldValue(`exercises.${index}.childrenAttributes.${cIndex}._destroy`, 1);
                    if (e.id == null) {
                      remove(index)
                    }
                  }
                };

                if (!!e._destroy) {
                  return null;
                }

                return (
                  <ExerciseChild
                    key={`${index}_ec_${cIndex}`} remove={removeField}
                    {...{ e, index, cIndex, exerciseCategories, exerciseOptions, setFieldValue }}
                  />
                );
              })}
            <div className="flex px-8 justify-center items-center py-3 bg-p3-500  rounded-b-lg">
              <div className="h-px w-full bg-gray-200"></div>
              <div
                className=" flex items-center justify-center cursor-pointer transform duration-500 hover:rotate-90 transition-all"
                onClick={() => {
                  push({ _destroy: 0 });
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="#86704D"
                  className="w-6 h-6 m-2 "
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                  ></path>
                </svg>
              </div>
              <div className="h-px w-full bg-gray-200"></div>
            </div>
          </>
        )}
      </FieldArray>

    </div>
  );
}
