import { Controller } from "stimulus"
import TomSelect from "tom-select"

export default class extends Controller {

  static targets = ["item"]

  connect() {

    setTimeout(function(){
      document.querySelector('#flash-notice').classList.add('hidden');
    },10000)

  }

  close() {
    document.querySelector('#flash-notice').classList.add('hidden');
  }

}
