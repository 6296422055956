import React, { Fragment } from "react";
import { Formik, FieldArray } from "formik";
import { useQuery, useMutation } from "@apollo/client";
import { Dialog, Transition } from "@headlessui/react";

import { Remove } from "@components/Icons";

import { NUTRITIONAL_PLAN } from "graphql/queries";
import { UPDATE_NUTRITIONAL_PLAN } from "graphql/mutations";
import PlanSupplement from "./PlanSupplement";

export default function PlanSupplementDrawer({ nutritionalPlanId,supplementOptions, supplementBrands, planMeals, onClick, open }) {
  const { data } = useQuery(NUTRITIONAL_PLAN, { variables: { id: nutritionalPlanId } });

  const [updateNutritionalPlan] = useMutation(UPDATE_NUTRITIONAL_PLAN);

  const validate = (values) => {
    return {};
  };

  const onSubmit = (values) => {

    updateNutritionalPlan({
      variables: {
        id: nutritionalPlanId,
        input: { 
          planSupplementsAttributes: values.planSupplements.map((w) => ({
            id: w.id,
            supplementId: w.supplementId,
            mealName: w.mealName,
            brand: w.brand,
            nutritionalPlanId: w.nutritionalPlanId,
            amount: w.amount || 0,
            unitMeasure: w.unitMeasure,
            _destroy: w._destroy,
          })) || []
        },
      },
      onCompleted: () => onClick(false),
    });
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 overflow-hidden z-50" onClose={onClick}>
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0" />

          <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-md">
                <div className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                  <div className="min-h-0 flex-1 flex flex-col pb-6 overflow-y-scroll ">
                    <div className="px-4 sm:px-6 bg-gray-50 py-8">
                      <div className="flex items-start justify-between ">
                        <Dialog.Title className="text-lg font-medium text-gray-900">
                          Supplementi generali
                        </Dialog.Title>
                        <div className="ml-3 h-7 flex items-center">
                          <button
                            type="button"
                            className=" p-2 rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring  focus:ring-gray-300"
                            onClick={() => onClick(false)}
                          >
                            <span className="sr-only">Chiudi</span>
                            <Remove />
                          </button>
                        </div>
                      </div>
                      <p className="mt-2 text-gray-700 text-sm pr-8">
                        Inserisci Supplementi generali per renderli disponibili nel PDF.
                      </p>
                    </div>
                    <div className="mt-6 relative flex-1 px-4 sm:px-6">
                      <Formik
                        enableReinitialize
                        validateOnMount
                        initialValues={{
                          planSupplements: data.nutritionalPlan.planSupplements.map((e) => ({
                            id: e.id,
                            mealName: e.mealName || '',
                            brand: e.brand || '',
                            amount: e.amount || '',
                            supplementId: e.supplementId,
                            nutritionalPlanId: e.nutritionalPlanId,
                            unitMeasure: e.unitMeasure || '',
                            _destroy: 0,
                          })),
                        }}
                        validate={validate}
                        onSubmit={onSubmit}
                      >
                        {(props) => (
                          <form id="my-form" onSubmit={props.handleSubmit}>
                            <FieldArray name="planSupplements">
                              {({ push, remove }) => (
                                <>
                                  <div className="min-w-full divide-y divide-gray-200">
                                    <div className="bg-white divide-y divide-gray-200">
                                      {props.values.planSupplements.map((e, index) => {
                                        
                                        const removeField = () => {
                                          let result = confirm("Sei sicuro?");
                                          if (result) {
                                            props.setFieldValue(`planSupplements.${index}._destroy`, 1);
                                            if (e.id == null) {
                                              remove(index)
                                            }
                                          }
                                        }

                                        if (!!e._destroy) {
                                          return null;
                                        }
                                        return (
                                          <PlanSupplement 
                                            key={e.id} 
                                            setFieldValue={props.setFieldValue}
                                            values={e}
                                            remove={removeField}
                                            {...{ index, supplementOptions, supplementBrands, planMeals }} 
                                          />
                                        );
                                      })}
                                    </div>
                                  </div>

                                  <div className="flex items-center justify-center py-2 border-t border-gray-200 mt-2">
                                    <div
                                      onClick={() => push({ _destroy: 0 })}
                                      className="cursor-pointer transform duration-500 hover:rotate-90 transition-all"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="#86704D"
                                        className="cursor-pointer w-8 h-8 p-1 text-gray-700"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth="2"
                                          d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                                        ></path>
                                      </svg>
                                    </div>
                                  </div>
                                </>
                              )}
                            </FieldArray>
                          </form>
                        )}
                      </Formik>
                    </div>
                  </div>
                  <div className="flex-shrink-0 px-4 py-4 flex justify-center items-center">
                    <button form="my-form" type="submit" className="btn btn-dark">
                      Salva
                    </button>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
