import React, { useState } from "react";
import cx from 'classnames';

import { WORKOUT_SHEET_STATE } from 'graphql/mutations';

import apolloClient from './apollo-client';

export default function WorkoutSheetStatus({ id, state_label, ...props }) {

  const [state, setState] = useState(props.state);

  const activate = (e) => {
    apolloClient.mutate({
      mutation: WORKOUT_SHEET_STATE,
      variables: { id, state: 'active' }
    });
    setState('active');
  }

  return (
    <div className="flex items-center">
      <span className={cx('font-bold font-medium capitalize px-2 inline-flex text-xs leading-5 rounded-full', { ['bg-p4-600 text-white']: state != 'active', ['bg-green-100 text-green-800']: state == 'active' })}>
        {state == 'active' ? 'Attiva' : state_label}
      </span>
      {(state == 'draft' || state == 'archived') && (<div className="btn ml-4" onClick={activate}>Attiva</div>)}
    </div>
  );
}
