import React, { useState } from "react";
import cx from 'classnames';
import Popup from 'reactjs-popup';
import ClipLoader from "react-spinners/ClipLoader";

import { useQuery, useMutation } from '@apollo/client';

import { NUTRITIONAL_PLAN } from 'graphql/queries';
import { UPDATE_NUTRITIONAL_DAY, CREATE_NUTRITIONAL_DAY, DELETE_NUTRITIONAL_DAY } from 'graphql/mutations';

export default function TabHeader({ nutritionalPlanId, activeNutritionalDay, setActiveNutritionalDay }) {

  const [hover, setHover] = useState(false);
  const [editing, setEditing] = useState(false);
  const [dayName, setDayName] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [editingId, setEditingId] = useState(null);

  const { loading, data, error } = useQuery(NUTRITIONAL_PLAN, {
    variables: { id: nutritionalPlanId },
    onCompleted: (data) => {
      
      if (data.nutritionalPlan.days[0]) { !activeNutritionalDay && setActiveNutritionalDay(data.nutritionalPlan.days[0].id) }
      
    }
  });
  
  const existingNames = data?.nutritionalPlan?.days?.map(day => day.name);

  const [updateNutritionalDay] = useMutation(UPDATE_NUTRITIONAL_DAY);

  const [deleteNutritionalDay] = useMutation(DELETE_NUTRITIONAL_DAY, {
    update(cache, { data: { deleteNutritionalDay } }) {
      const identify = cache.identify({ deleteNutritionalDay });
      cache.evict({ id: identify });
      cache.gc();
    },
    refetchQueries: [NUTRITIONAL_PLAN]
  });

  const [createNutritionalDay] = useMutation(CREATE_NUTRITIONAL_DAY, {
    variables: { id: nutritionalPlanId },
    onCompleted: (data) => {

      setActiveNutritionalDay(data.createNutritionalDay.id)

    }
  })

  if (loading) { return <ClipLoader /> }

  if (error) return `Error! ${error}`;

  const remove = (id) => {
    let result = confirm("Sei sicuro?");
    if (result) {
      deleteNutritionalDay({
        variables: {
          id
        }
      })
    }
  }

  const update = (id, value) => {

    updateNutritionalDay({
      variables: { id, input: { name: value } },
      optimisticResponse: {
        updateNutritionalDay: {
          id,
          name: value,
          __typename: "NutritionalDay"
        }
      }
    });

    setEditing(false)

  }

  const rename = (id, newName) => {
    if (newName === '') {
      setIsEditing(false);
      return;
    }
  
    if (['A', 'B', 'C', 'D', 'E', 'F', 'G'].includes(newName)) {
      if (!existingNames.includes(newName)) {
        setDayName(newName);
        update(id, newName);
        setIsEditing(false);
      } else {
        alert('Il nome del giorno esiste già');
      }
    } else {
      alert('Il nome del giorno deve essere tra A e G');
    }
  };

  return (
    <div className="border-b-2 border-gray-300 mt-4">
      <ol className="flex overflow-x-auto items-center pt-4">
        {data.nutritionalPlan.days.map(w => (
          <li key={w.id} className={cx('pb-2 cursor-pointer mr-4 min-w-3rem border-b-4', { 'border-p1-600': activeNutritionalDay === w.id })}>
            {editing == w.id && ((w.category !== "pre_workout") && (w.category !== "post_workout") && (w.category !== "intra_workout")) ? (
              <input
                className="shadow-none animate-pulse border-0 outline-none appearance-none bg-transparent italic text-p2-600 font-medium"
                type="string"
                defaultValue={w.name}
                onBlur={(e) => update(w.id, e.target.value)}
              />
            ) : (
              <div
                className="flex"
                onMouseEnter={() => setHover(w.id)}
                onMouseLeave={() => setHover(false)}
                onClick={() => setActiveNutritionalDay(w.id)}
              >
                <span className="w-full text-center relative">
                  {
                    w.category !== "pre_workout" &&
                    w.category !== "post_workout" &&
                    w.category !== "intra_workout" && (
                      <div>
                        <p style={{ fontSize: 10 }} className="absolute uppercase w-full bottom-6 left-0 text-center text-gray-500 font-semibold">Giorno</p>
                      </div>
                    )}
                  <div className="inline-block">
                    {w.name}
                  </div>
                </span>
                <div className="w-5 relative">
                  {hover === w.id && ((w.category !== "pre_workout") && (w.category !== "post_workout") && (w.category !== "intra_workout")) && (
                    <Popup trigger={
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 cursor-pointer absolute right-0" fill="none" viewBox="0 0 24 24" stroke="#86704D">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z" />
                      </svg>
                    } position="top center">
                      {close => (
                        <div className="flex items-center space-x-2">
                          <div>
                            {isEditing && w.id === editingId ? (
                                <input type="text" value={dayName} className="m-2 rounded-xl border-2 border-gray-300 px-2 py-2"
                                  onChange={(e) => setDayName(e.target.value)} onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                      rename(w.id, dayName);
                                      setIsEditing(false);
                                    }
                                  }}
                                />
                            ) : ( 
                              <div className="py-2 text-center bg-p2-500 shadow rounded-xl">
                                <p onClick={() => { setIsEditing(true); setEditingId(w.id); }}
                                  className="text-sm text-white cursor-pointer py-2 px-4 hover:bg-gray-500"
                                >Rinomina</p>
                              </div> 
                            )}
                          </div>
                          <div className="py-2 text-center bg-p2-500 shadow rounded-xl">
                            <p onClick={() => remove(w.id)} className="text-sm text-white cursor-pointer py-2 px-4 hover:bg-gray-500">Elimina</p>
                          </div>
                        </div>
                      )}
                    </Popup>
                  )}
                </div>
              </div>
            )}
          </li>
        ))}

        {data.nutritionalPlan.days.length < 10 && (
          <li className="border-l-2 border-gray-200 my-1 pl-2" onClick={() => createNutritionalDay()}>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 cursor-pointer text-gray-500 flex items-center" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
            </svg>
          </li>
        )}

      </ol>
    </div>
  );
}

