import React from 'react';

import Select from '@components/fields/Select';
import InputField from '@components/fields/Input';
import { foodUnitOptions } from '@components/options';

import RemoveButton from '@components/RemoveButton';

export default function FoodChild({
  index,
  fIndex,
  remove,
  foodCategories,
  foodOptions,
  setFieldValue,
  values,
  ...props
}) {

  return (
    <div className="w-full bg-p3-500 my-2 border border-gray-200 relative">

      <RemoveButton onClick={remove} />

      <div className="grid grid-cols-2 items-start gap-4 p-4">
        <div>
          <Select
            name={`foods.${index}.childrenAttributes.${fIndex}.category`}
            label="Categoria"
            options={foodCategories}
            onChange={(value) => {
              setFieldValue(`foods.${index}.childrenAttributes.${fIndex}.category`, value.value);
            }}
          />
          <div className="pt-3">
            <Select
              name={`foods.${index}.childrenAttributes.${fIndex}.foodId`}
              label="Alimento"
              options={values && foodOptions.filter((value) => value.category === values.category)}
              onChange={(value) => {
                setFieldValue(`foods.${index}.childrenAttributes.${fIndex}.foodId`, value.value);
                setFieldValue(`foods.${index}.childrenAttributes.${fIndex}.carbohydrates`, value.carbohydrates);
                setFieldValue(`foods.${index}.childrenAttributes.${fIndex}.proteins`, value.proteins);
                setFieldValue(`foods.${index}.childrenAttributes.${fIndex}.fats`, value.fats);
                setFieldValue(`foods.${index}.childrenAttributes.${fIndex}.salt`, value.salt);
              }}
            />
          </div>
        </div>
        <div>
          <div className="flex items-start space-x-4">
            <div className="w-1/2 mt-1">
              <InputField name={`foods.${index}.childrenAttributes.${fIndex}.amount`} label="Quantità" type="number" />
            </div>
            <div className="w-1/2">
              <Select name={`foods.${index}.childrenAttributes.${fIndex}.unitMeasure`} label="Unità" options={foodUnitOptions} />
            </div>
          </div>
          <div className="flex items-start space-x-4">
            <div className="w-1/2">
              <InputField name={`foods.${index}.childrenAttributes.${fIndex}.spanAmount`} label="Quantità Span." type="number" />
            </div>
            <div className="w-1/2">
              <InputField name={`foods.${index}.childrenAttributes.${fIndex}.unitSpanMeasure`} label="Unità Span." type="string" />
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}
