import { gql } from '@apollo/client';

export const EXERCISE_FIELDS = gql`
  fragment exerciseFields on Exercise {
    id
    position
    series
    recovery
    repetition
    haveProgression
    notes
    exerciseType
    category
    presetName
    weightAdvisor
    effort
    workoutId
    exerciseId
    childrenAttributes {
      id
      repetition
      weightAdvisor
      effort
      notes
      workoutId
      exerciseId
    }
  }
`;

export const MEAL_FIELDS = gql`
  fragment mealFields on NutritionalMeal {
    id
    name
    foods {
      id
      position
      foodId
      nutritionalMealId
      category
      amount
      unitMeasure
      spanAmount
      unitSpanMeasure
      carbohydrates
      proteins
      fats
      salt
      childrenAttributes {
        id
        foodId
        category
        amount
        unitMeasure
        spanAmount
        unitSpanMeasure
        carbohydrates
        proteins
        fats
        salt
      }
    }
  }
`;
