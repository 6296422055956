export const workoutOptions = [
  { label: "Pesistica", value: "normal" },
  { label: "Corpo Libero", value: "free" },
];

export const dayOptions = [
  { label: "Indifferente", value: "default" },
  { label: "Rest", value: "rest" },
  { label: "Workout", value: "workout" },
];

export const foodUnitOptions = [
  { label: "gr", value: "gr" },
  { label: "ml", value: "ml" },
];

export const supplementUnitOptions = [
  { label: "capsule", value: "capsule" },
  { label: "gr", value: "gr" },
];

export const nutritionalPlanOptions = [
  { label: "Flex", value: "flex" },
  { label: "Ciclico", value: "cyclical" },
];

export const objectiveOptions = [
  { label: "Tonificazione", value: "tonification" },
  { label: "Dieta Ingrassante", value: "fattening diet" },
  { label: "Dieta Dimagrante", value: "weight_loss_diet" },
];

export const radioOptions = [
  { label: "Si", value: "true" },
  { label: "No", value: "false" },
];
