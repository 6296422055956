import React from 'react';
import { useQuery } from '@apollo/client';

import { WORKOUT_EXERCISE } from 'graphql/queries';

export default function ExerciseStats({ workoutExerciseId, setShowStats }) {

  const { loading, data } = useQuery(WORKOUT_EXERCISE, { variables: { id: workoutExerciseId } });

  if (loading) {
    return null;
  }

  const sumTotals = (array) => {
    let sum = 0;
    let total = 0;

    array.forEach((item) => {
      sum += item;
    });

    total = sum / array.length;
    return total;
  };

  const exerciseDataInfo = (data) => {
    let newData = [];
    data.map((str) => {
      str.map((value) => newData.push(Number(value)));
    });
    newData.flat();
    return Math.round(sumTotals(newData));
  };

  return (
    <div className="w-1/2 absolute top-2 right-2 z-20 rounded-xl shadow-lg border">
      {data.workoutExercise.exerciseInfos.length >= 0 && (
        <div className="p-4 bg-gray-50 rounded-xl">
          <div className="flex justify-between">
            <p className="font-medium text-p2-500">Statistiche</p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 cursor-pointer"
              fill="none"
              viewBox="0 0 24 24"
              stroke="#8990AD"
              onClick={() => setShowStats(false)}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </div>
          <div className="mt-2 text-sm space-y-1">
            <p>
              <span className="font-medium mr-2 text-p2-550"> Media Fatica: </span>
              {exerciseDataInfo(data.workoutExercise.exerciseInfos.map((w) => w.efforts)) || '-'}
            </p>
            <p>
              <span className="font-medium mr-2 text-p2-550"> Media Fatica: </span>
              {exerciseDataInfo(data.workoutExercise.exerciseInfos.map((w) => w.weights)) || '-'} kg
            </p>
          </div>
        </div>
      )}
    </div>
  );

}
